import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Brand } from '../../assets/images/46.png';
import { useState } from 'react';
import "./Navbar.css";
import { FaBars } from 'react-icons/fa';

const Navbar = () => {

  const [showNavbar, setShowNavbar] = useState(false)

  const red = "#C65447";
  const redBackground = "#F4DDDA";
  const green = "#509E6F";
  const greenBackground = "#DCECE2";
  const blue = "#1961A8";
  const blueBackground = "#D1DFEE";
  const yellow = "#FFC337";
  const orange = "#ED6742";
  const gold = "#F0B52C";
  const goldBackground = "#FCF0D5";

  const location = useLocation();

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  }

  const hideNavbar = () => {
    setShowNavbar(false);
  }

  return (
    <nav className="navbar" style={{display: location.pathname === '/host' ? 'none' : 'block'}}>
      <div className="container">
        <div className="logo">
          <a className='segment-item' style={{display:'flex', alignItems: 'center', fontSize: '35px', color: 'white', textDecoration: 'none'}} href='/'>yikyak.tv</a>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar} style={{paddingRight: '24px'}}>
          <FaBars />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`} style={{background: '#7BB4F0'}}>
          <ul>
            <li>
              <NavLink className={'nav-item'} to="/" onClick={() => {hideNavbar()}}>home</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="/my-games" onClick={() => {hideNavbar()}}>my games</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="https://yikyakgames.com/discover" onClick={() => {hideNavbar()}}>discover</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="https://yikyakgames.com/get-started" onClick={() => {hideNavbar()}}>how to play</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} style={{marginRight: '12px'}} to="/account" onClick={() => {hideNavbar()}}>account</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar