import React, { useRef, useEffect, useState } from "react";
import "../App.css";
import { FaMobileAlt } from "react-icons/fa";
import Lottie from "lottie-react";

class PlayerTutorial extends React.Component {  
    render() {
        const { currentPlayer, activeHost, isVIP, onClick, style } = this.props;

        // Colors
        const red = "#C65447";
        const purple = "#805CE8";
        const purpleBackground = "#805CE820";
        const green = "#409F91";
        const greenBackground = "#409F9120";
        const blue = "#68A6EB";
        const yellow = "#E8C352";
        const orange = "#DA6E2C";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D5";

        const playerWaitingForHost = require(`../assets/player-waiting.json`);

        return (
            <div style={style}>
                <div>
                    <p style={{margin: '48px 32px', textAlign: 'center', color: 'white'}}>keep an eye on the shared screen to learn how to play, the host can skip at any time</p>
                    <Lottie style={{height: 'auto', width: 'auto'}} animationData={playerWaitingForHost} />
                </div>
            </div>
        );
    }
}
  
export default PlayerTutorial;