export const RED = "#C65447";
export const RED_BACKGROUND = "#C6544720";
export const PURPLE = "#805CE8";
export const PURPLE_BACKGROUND = "#805CE820";
export const GREEN = "#409F91";
export const GREEN_BACKGROUND = "#409F9120";
export const BLUE = "#68A6EB";
export const YELLOW = "#E8C352";
export const ORANGE = "#DA6E2C";
export const GOLD = "#F0B52C";
export const GOLD_BACKGROUND = "#FCF0D5";
export const PINK = "#EE62AD";
export const GRAY = "#333333";