import React from "react";
import "./Footer.css";

const Footer = () => {

  return (
    <div className="footer">
      <p style={{margin: "auto", textAlign: "center", paddingTop: '32px', color: 'white'}}>© 2024 Fundaptive, LLC. All Rights Reserved.</p>
    </div>
  );
};

export default Footer;        