import React from "react";
import "../App.css";
import { FaMobileAlt, FaMinusCircle, FaAngleDoubleUp } from "react-icons/fa";
import LoadingComponent from "../components/LoadingComponent";
import {  db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { RED, PURPLE, YELLOW, GREEN, ORANGE, BLUE, GREEN_BACKGROUND, PURPLE_BACKGROUND, GOLD, GOLD_BACKGROUND } from "../components/Colors";

class HostWaitingRoom extends React.Component {  
    render() {
        const { roomKey, activeHost, style, onClick, onClickStart } = this.props;
        
        function currentGameType() {
            return activeHost?.gameType ?? "standard"
        }

        return (
            <div style={style}>
                <div style={{display: 'flex'}}>
                    <div style={{width: '50%'}}>
                        <h2 className="survey-title" style={{textAlign: 'center', fontSize: "1.8vw"}}>join on your <FaMobileAlt style={{verticalAlign: 'bottom'}}/> at: <b><span style={{color: PURPLE}}>yikyak</span><span style={{color: PURPLE}}>.tv</span></b></h2>
                        <p style={{textAlign: 'center', fontSize: '1.4vw'}}>your room code is</p>
                        <h1 className="survey-title" style={{textAlign: 'center', fontSize: '7vw', margin: '0'}}><span style={{textTransform: 'lowercase'}}>{activeHost?.code}</span></h1>
                        <img style={{objectFit: "contain", margin: '24px auto', height: '13vw', width: '13vw', display: 'block'}} src={require(`../assets/images/avatar5.png`)} />
                        <h1 className="survey-title" style={{textAlign: 'center', fontSize: '5vw', marginTop: '0px', marginBottom: '0px'}}><b><span style={{color: PURPLE}}>y</span><span style={{color: RED}}>i</span><span style={{color: YELLOW}}>k</span><span style={{color: GREEN}}> y</span><span style={{color: ORANGE}}>a</span><span style={{color: BLUE}}>k</span></b></h1>
                        <div style={{margin: '16px 0px 48px 0px'}}>
                            <h1 className="survey-title" style={{textAlign: 'center', margin: '0px auto', fontSize: '2.4vw', width: 'fit-content', padding: '16px 40px', borderRadius: '50px', color: `${currentGameType() === "standard" ? GREEN : PURPLE}`, background: `${currentGameType() === "standard" ? GREEN_BACKGROUND : PURPLE_BACKGROUND}`}}>{currentGameType() === "standard" ? "round robin 🔄" : "polling party 🎉"}</h1>
                        </div>
                        <p style={{textAlign: 'center', fontSize: '1.4vw'}}>make sure you see all players on the screen before you begin press the button below to start the game once everyone is ready</p>
                        <a className="final-submit-btn green-btn-dash" style={{padding: '12px', marginBlock: '0px'}} onClick={() => {onClick('1')}}>Start Game</a>
                    </div>
                    {/* TEAMS */}
                    <div style={{width: '50%'}}>
                        {(activeHost?.gameType === "pollparty")? (
                            <div>
                                {/* TEAM 1 */}
                                <div>
                                    <h1 style={{marginBottom: '8px', fontSize: '2.5vw'}}>team 1</h1>
                                    <p style={{marginTop: '8px', fontSize: '1.2vw'}}>suggest a team name on your device</p>
                                    <div style={{display: 'flex', margin: '48px 0px', flexWrap: 'wrap', gap: '24px', rowGap: '36px'}}>
                                        {activeHost?.players?.filter(p => ((p.team ?? 1) === 1))?.map(player => (
                                            <div key={player.id} style={{width: '6vw'}}>
                                                <img style={{objectFit: "contain", height: '6vw', margin: '0 auto', display: 'block'}} src={require(`../assets/images/avatar${(player.avatar)}.png`)} />
                                                <h2 className="survey-title" style={{color: `${player.color}`, textAlign: 'center', marginBottom: '6px', fontWeight: '600', textTransform: 'lowercase', fontSize: '2.5vw', marginTop: '0px'}}>{player.name}</h2>
                                                <div id="mouse_over">
                                                    <a className="final-submit-btn red-btn" style={{padding: '6px', borderRadius: '20px'}} onClick={() => {
                                                        // onClick(player.id);
                                                    }}><FaMinusCircle style={{height: '12px', width: '12px'}}/> kick player</a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* TEAM 2 */}
                                <div>
                                <h1 style={{marginBottom: '8px', fontSize: '2.5vw'}}>team 2</h1>
                                    <p style={{marginTop: '8px', fontSize: '1.2vw'}}>suggest a team name on your device</p>
                                    <div style={{display: 'flex', margin: '48px 0px', flexWrap: 'wrap', gap: '3vw', rowGap: '1vw'}}>
                                        {activeHost?.players?.filter(p => ((p.team ?? 1) === 2))?.map(player => (
                                            <div key={player.id} style={{width: '6vw'}}>
                                                <img style={{objectFit: "contain", height: '6vw', margin: '0 auto', display: 'block'}} src={require(`../assets/images/avatar${(player.avatar)}.png`)} />
                                                <h2 className="survey-title" style={{color: `${player.color}`, textAlign: 'center', marginBottom: '6px', fontWeight: '600', textTransform: 'lowercase', fontSize: '2.5vw', marginTop: '0px'}}>{player.name}</h2>
                                                <div id="mouse_over">
                                                    <a className="final-submit-btn red-btn" style={{padding: '6px', borderRadius: '20px'}} onClick={() => {
                                                        // onClick(player.id);
                                                    }}><FaMinusCircle style={{height: '12px', width: '12px'}}/> kick player</a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                         ) : (
                            <div style={{display: 'flex', margin: '48px 0px', flexWrap: 'wrap', gap: '1%', rowGap: '32px'}}>
                                {activeHost?.players?.map(player => (
                                    <div key={player.id} style={{width: '30%'}}>
                                        <img style={{objectFit: "contain", height: '120px', margin: '0 auto', display: 'block'}} src={require(`../assets/images/avatar${(player.avatar)}.png`)} />
                                        <h2 className="survey-title" style={{color: `${player.color}`, textAlign: 'center', marginBottom: '6px', fontWeight: '600', textTransform: 'lowercase', fontSize: '30px'}}>{player.name}</h2>
                                        <div id="mouse_over">
                                            <a className="final-submit-btn red-btn" style={{padding: '6px', borderRadius: '20px'}} onClick={() => {
                                                // onClick(player.id);
                                            }}><FaMinusCircle style={{height: '12px', width: '12px'}}/> kick player</a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>
        );
    }
}
  
export default HostWaitingRoom;