import React from "react";
import "../App.css";
import Lottie from "lottie-react";

class PlayerWaitingRoom extends React.Component {  
    render() {
        const { currentPlayer, onClick, style } = this.props;
        const playerWaitingForHost = require(`../assets/player-waiting.json`);

        return (
            <div style={style}>
                {(currentPlayer.isVIP)? (
                <div>
                    <a className="final-submit-btn green-btn-dash" style={{maxWidth: '200px', margin: '24px auto'}} onClick={() => {
                        onClick();
                    }}>start game</a>
                    <p style={{textAlign: "center", color: '#DDDDDD', margin: '16px'}}>be sure you see your entire group on the screen before pressing 'start game'</p>
                </div>
                ) : (
                    <div>
                        <p style={{margin: '24px auto', textAlign: 'center', color: 'white', fontWeight: '600', }}>waiting for the 'vip' game to start</p>
                    </div>
                )}
                <div>
                    <div>
                        <p style={{margin: '24px auto', textAlign: 'center', color: 'white', fontWeight: '600', }}>waiting for game to start</p>
                        <Lottie style={{height: 'auto', width: 'auto'}} animationData={playerWaitingForHost} />    
                    </div>
                </div>
            </div>
        );
    }
}
  
export default PlayerWaitingRoom;