import React, { useRef, useEffect, useState } from "react";
import "../App.css";
import Lottie from "lottie-react";
import PlayerCard from "../resources/PlayerCard";
import ConfettiComponent from "../components/ConfettiComponent";

class PlayerPlaying extends React.Component {  
    render() {
        const { activeHost, isVIP, onClick, onEndGameClicked, onContinueClicked, skipPlayerClicked, style, isCurrentPlayer, cards } = this.props;

        // Colors
        const red = "#C65447";
        const green = "#509E6F";
        const blue = "#1961A8";
        const yellow = "#FFC337";
        const orange = "#ED6742";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D5";

        return (
            <div style={style}>
                {(activeHost?.currentState === "waitingForVIPToProceed")? (
                    <div>
                        <ConfettiComponent style={{height: '50vw'}} />
                        {(isVIP)? (
                            <div>
                                <p style={{margin: '24px auto', textAlign: 'center', color: 'white', fontSize: '24px'}}>you completed this rank! review it on the screen with your team and then tap 'continue' to proceed to the next project!</p>
                                <a className="final-submit-btn green-btn-dash" style={{maxWidth: '200px', margin: '24px auto'}} onClick={() => {
                                    onContinueClicked();
                                }}>continue</a>
                            </div>
                        ) : (
                            <div>
                                <p style={{margin: '24px auto', textAlign: 'center', color: 'white', fontSize: '24px'}}>you completed this rank! review the cards with your team!</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        {(isCurrentPlayer)? (
                            <div>
                                <p style={{margin: '24px auto', textAlign: 'center', color: 'white', fontSize: '24px'}}><b>it's your turn!</b><br></br><br></br>play a card by selecting it, and then hitting 'play'</p>
                            </div>
                        ) : (
                            <div>
                                <p style={{margin: '24px auto', textAlign: 'center', color: 'white', fontSize: '24px'}}>review your cards, your turn is coming up soon</p>
                            </div>
                        )}
                    </div>
                )}
                {(activeHost?.currentState != "waitingForVIPToProceed")? (
                    <div className="card-collection" style={{width: '90%'}}>
                        {cards.map(card => (
                            <PlayerCard 
                            card={card.data()}
                            onClick={() => {
                                onClick(card.data());
                            }} />
                        ))}
                    </div>
                ) : (
                    <div>

                    </div>
                )}
                {(isVIP && activeHost?.currentState != "waitingForVIPToProceed")? (
                <div>
                    <h3 style={{textAlign: "center", color: '#FFFFFF', margin: '16px', fontSize: '24px'}}>vip controls</h3>
                    <p style={{textAlign: "center", color: '#DDDDDD', margin: '16px'}}>is a team member taking too long? are they in the bathroom? press this button to keep the game going and skip to the next player!</p>
                    <a className="final-submit-btn blue-btn-dash" style={{maxWidth: '200px', margin: '24px auto'}} onClick={() => {
                        skipPlayerClicked();
                    }}>skip current player</a>

                    <p style={{textAlign: "center", color: '#DDDDDD', margin: '64px 16px 16px 16px'}}>be sure your game is over before pressing this button!</p>
                    <a className="final-submit-btn red-btn-dash" style={{maxWidth: '200px', margin: '24px auto'}} onClick={() => {
                        onEndGameClicked();
                    }}>end game</a>
                    
                </div>
                ) : (
                    <div>
                        {(isCurrentPlayer)? (
                            <div>
                                <p style={{textAlign: "center", color: '#DDDDDD', margin: '16px'}}>need to use the bathroom? don't have a card to play? discuss with your team and see if it makes sense to skip your turn (try your best to talk things through with your team)</p>
                                <a className="final-submit-btn blue-btn-dash" style={{maxWidth: '200px', margin: '24px auto'}} onClick={() => {
                                    skipPlayerClicked();
                                }}>skip turn</a>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        
                    </div>
                )}
            </div>
        );
    }
}
  
export default PlayerPlaying;