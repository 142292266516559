import React, { useRef, useEffect, useState } from "react";
import "../App.css";

class HostCreateGame extends React.Component {  
    render() {
        const { style, onClick } = this.props;

        return (
            <div style={style}>
                <div style={{display: 'flex', height: '300px', marginTop: '32px'}}>
                    <img style={{objectFit: "contain", margin: '32px auto'}} src={require(`../assets/images/avatar5.png`)} />
                </div>
                <a className="final-submit-btn green-btn-dash" href="/my-games" style={{textAlign: 'center', maxWidth: '300px'}}>create new game</a>
            </div>
        );
    }
  }
  
  export default HostCreateGame;