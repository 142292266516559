import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../login/Login.css";
import "../App.css";
import { auth, signInWithGoogle, signInWithApple, db, logout } from "../firebase";
import { query, collection, getDoc, getDocs, where, doc } from "firebase/firestore";
import LoadingComponent from "../components/LoadingComponent";
import ManagePlanCell from "../components/ManagePlanCell";
import { FaApple, FaGoogle } from "react-icons/fa";

function Solo() {
  const [user, loading] = useAuthState(auth);
  const [accountType, setAccountType] = useState("");
  const [planRenewDate, setPlanRenewDate] = useState("...");
  const navigate = useNavigate();

  const handleSignInWithApple = () => {
    signInWithApple(auth);
  };

  const fetchAccountType = async () => {
    try {
      if (user == null) {
        setAccountType("basic");
        console.log("No user data");
        return;
      }
      const querySnapshot = await getDocs(collection(db, "customers", user.uid, "subscriptions"));
      var hasSubscription = false;
      var productId = "";
      querySnapshot.forEach((doc) => {
        const status = doc.data().status;
        const renewDate = doc.data()["trial_end"].toDate().toDateString();
        if (!hasSubscription) {
          if (status == "trialing" || status == "active") {
            hasSubscription = true;
            productId = doc.data().product.id;
            setPlanRenewDate(renewDate);
          }
        }
      });
      if (hasSubscription) {
        if (productId == "prod_Pp6vu0QgLQkANr") {
          setAccountType("premium");
        } else if (productId == "prod_Pp6sRoYVIQHfFo") {
          setAccountType("plus");
        }
      } else {
        setAccountType("basic");
      }
    } catch (err) {
      console.error(err);
      console.log("An error occured while fetching user data");
    }
  };

  const red = "#C65447";
  const redBackground = "#F4DDDA";
  const green = "#409F91";
  const blue = "#1961A8";
  const blueBackground = "#D1DFEE";
  const yellow = "#FFC337";
  const orange = "#ED6742";
  const gold = "#F0B52C";
  const goldBackground = "#FCF0D5";
  const purple = "#977AE8";

  const loadingAnimationData = require(`../assets/images/host-waiting.json`);

  useEffect(() => {
    if (loading) return;
    fetchAccountType();
  }, [user, loading]);
  return (
    <div className="page-container">
      {(loading)? (
        <div style={{minHeight: '1200px'}}>
          <LoadingComponent style={{marginTop: '24px', height: '80px'}}/>
        </div>
      ) : (
        <div>
          {(user == null)? (
            <div style={{marginTop: '80px', minHeight: '1200px'}}>
              <h1 className="dashboard-section-title" style={{fontSize: '80px'}}><span style={{color: '#ffffff'}}>yik yak</span></h1>
              <h1 className="dashboard-section-title" style={{fontSize: '80px'}}><span style={{color: green}}>solo</span></h1>
              <p className="dashboard-section-subtitle">learn new communication skills. identify some of the most common communication challenges and solutions that fit so you can tackle any communication issue with ease.</p>
              <img style={{objectFit: "contain", height: '200px', width: '100%', marginBottom: '32px'}} src={require(`../assets/images/30.png`)} />
              <div style={{display: 'grid'}}>
                <a className="final-submit-btn green-btn-dash" style={{textAlign: 'center', marginTop: '24px'}} onClick={() => {
                        // attemptToStartGame();
                        navigate("/account");
                      }}>log in to start playing</a>
              </div>
            </div>
          ) : (
            <div style={{marginTop: '80px', minHeight: '1200px'}}>
              <h1 className="dashboard-section-title" style={{fontSize: '80px'}}><span style={{color: '#ffffff'}}>yik yak</span></h1>
              <h1 className="dashboard-section-title" style={{fontSize: '80px'}}><span style={{color: purple}}>solo</span></h1>
              <p className="dashboard-section-subtitle">learn new communication skills. identify some of the most common communication challenges and solutions that fit so you can tackle any communication issue with ease.</p>
              <img style={{objectFit: "contain", height: '200px', width: '100%', marginBottom: '32px'}} src={require(`../assets/images/30.png`)} />
              <div style={{display: 'grid'}}>
                <a className="final-submit-btn purple-btn-dash" style={{textAlign: 'center', marginTop: '24px'}} onClick={() => {
                        // attemptToStartGame();
                        navigate("/play");
                      }}>start game</a>
              </div>
            </div>
          )}
         </div>
      )}
    </div>
  );
}
export default Solo;