import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import "../App.css";
import "./Pricing.css";
import { auth, db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import PricingPage from "./PricingPage";

function Pricing() {
  const [user, loading] = useAuthState(auth);
  const [accountType, setAccountType] = useState("basic");
  const [userEmail, setUserEmail] = useState("");

  const fetchAccountType = async () => {
    try {
      if (user === null) {
        setAccountType("basic");
        console.log("No user data");
        return;
      }
      setUserEmail(user?.email ?? "");
      const querySnapshot = await getDocs(collection(db, "customers", user.uid, "subscriptions"));
      var hasSubscription = false;
      var productId = "";
      querySnapshot.forEach((doc) => {
        const status = doc.data().status;
        if (!hasSubscription) {
          if (status === "trialing" || status === "active") {
            hasSubscription = true;
            productId = doc.data().product.id;
          }
        }
      });
      if (hasSubscription) {
        if (productId === "prod_PbBu30DBX1c1j0") {
          setAccountType("premium");
        } else if (productId === "prod_POZmffO88V5Kwe") {
          setAccountType("plus");
        }
      } else {
        setAccountType("basic");
      }
    } catch (err) {
      console.error(err);
      console.log("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    fetchAccountType();
  }, [user, loading]);
  return (
    <div className="page-container" style={{maxWidth: '1000px'}}>
        <div style={{marginTop: '64px', display: 'flex', alignItems: 'center', marginBottom: '64px'}}>
          <div style={{marginRight: '16px'}}>
              <h1 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '48px'}}>pricing</h1>
              <p className="dashboard-section-subtitle" style={{textAlign: 'left'}}>find the right subscription for you</p>
          </div>
          <div style={{display: 'grid'}}>
              <img alt={"pricing image"} style={{objectFit: "contain", width: '150px'}} src={require(`../assets/images/49.png`)} />
          </div>
        </div>
        <div>
          <PricingPage email={userEmail} />
        </div>
    </div>
  );
}

export default Pricing;