import React from "react";
import "../App.css";
import {  db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";

class PlayerWaitingRoomPollParty extends React.Component {  
    render() {
        const { roomKey, activeHost, currentPlayer, onClick, onClickChangeTeam, style } = this.props;

        const avatars = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

        function isAvatarAvailable(avatar) {
            var count = activeHost?.players.filter((p) => {
                return Number(p.avatar) === Number(avatar);
            })?.length ?? 0;
            return count === 0 || currentPlayer.avatar === avatar;
        }

        function changeToAvatar(avatar) {
            const roomRef = doc(db, "hosts", roomKey);
            var newPlayer = currentPlayer.avatar = avatar;
            for (var i = 0; i < activeHost.players.count; i++) {
                if (activeHost.players[i].id === currentPlayer.id) {
                    activeHost.players[i] = newPlayer;
                }
            }

            updateDoc(roomRef, {
                players: activeHost.players
            });
        }

        return (
            <div style={style}>
                <div>
                    <p style={{margin: '24px auto', textAlign: 'center', color: 'white', fontWeight: '600', }}>waiting for the host computer to start the game</p>
                </div>
                <div>
                    <div>
                        <p style={{margin: '24px auto', textAlign: 'center', color: 'white', fontWeight: '600'}}>select which team you would like to join</p>
                        <a className="final-submit-btn blue-btn-dash" style={{maxWidth: '200px', margin: '24px auto'}} onClick={() => {
                            onClickChangeTeam();
                        }}>switch to team {(currentPlayer.team ?? 1) === 1 ? '2' : '1'}</a>
                        <p style={{margin: '24px auto', textAlign: 'center', color: 'white', fontWeight: '600', }}>select your avatar</p>
                        <div style={{display: 'flex', flexWrap: 'wrap', gap: '24px', rowGap: '12px', margin: '32px', justifyContent: 'center'}}>
                            {avatars.map(avatar => (
                                <div>
                                    <img alt={"player avatar"} style={{objectFit: "contain", height: '60px', margin: '0 auto', padding: '10px', display: 'block', cursor: `${isAvatarAvailable(avatar) ? 'pointer' : ''}`, border: `${currentPlayer.avatar === avatar ? '2px white solid' : 'none'}`, borderRadius: `20px`, opacity: `${isAvatarAvailable(avatar) ? 1.0 : 0.5}`}} src={require(`../assets/images/avatar${avatar}.png`)} onClick={() => {
                                        if (isAvatarAvailable(avatar) && avatar !== currentPlayer.avatar) {
                                            changeToAvatar(avatar);
                                        }
                                    }} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
  
export default PlayerWaitingRoomPollParty;