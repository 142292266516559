import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where, onSnapshot, doc, updateDoc } from "firebase/firestore";
import PlayerPlayCardOverlay from "./PlayerPlayCardOverlay";
import Cookies from 'universal-cookie';
import PlayerWaitingRoom from "./PlayerWaitingRoom";
import PlayerTutorial from "./PlayerTutorial";
import PlayerPlaying from "./PlayerPlaying";
import ProTalkCard from "../resources/ProTalkCard";
import PlayerPlayingPollParty from "./PlayerPlayingPollParty";
import { PURPLE } from "../components/Colors";
import PlayerWaitingRoomPollParty from "./PlayerWaitingRoomPollParty";

function Player() {
    const [user, loading, error] = useAuthState(auth);
    const [activeHost, setActiveHost] = useState(null);
    const [isVIP, setIsVIP] = useState(false);
    const [isCurrentPlayer, setIsCurrentPlayer] = useState(false);
    const [roomKey, setRoomKey] = useState('');
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    const [roomClosed, setRoomClosed] = useState(false);
    const cookies = new Cookies();

    const [solutionCards, setSolutionCards] = useState([]);
    const [challengeCards, setChallengeCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState();

    // Constants
    const freshChallengeCards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26];
    const freshSolutionCards = [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52];

    const openOverlay = () => {
        setOverlayOpen(true);
    };

    const closeOverlay = () => {
        setOverlayOpen(false);
    };

    const openRoomClosed = () => {
        setRoomClosed(true);
    }

    const closeRoom = () => {
        navigate("/");
    };

    const attemptToStartGame = async () => {
        // Check for at least 2 players
        if (activeHost?.players.length < 2) {
            alert("Please be sure that there are 2 players in your room before you start your game!");
            return;
        }
        if (activeHost.gameType === 'pollparty') {
            const team1Count = (activeHost?.players.filter((p) => { return p.team === 1 || p.team == null })).length ?? 0
            const team2Count = (activeHost?.players.filter((p) => { return p.team === 2 })).length ?? 0
            if (Math.abs(team1Count - team2Count) > 1) {
                alert("In order to start your game the teams should be more even 😊");
                return;
            };
        }
        if (roomKey != "") {
            console.log(`ROOM KEY: ${roomKey}`);
            const roomRef = doc(db, "hosts", roomKey);
            await updateDoc(roomRef, {
            currentState: "tutorial"
            });
        }
    };

    const switchTeam = async () => {
        if (roomKey != "") {
            console.log(`ROOM KEY: ${roomKey}`);
            const roomRef = doc(db, "hosts", roomKey);
            var myPlayer = currentPlayer();
            if (myPlayer.team == null || myPlayer.team === 1) {
                myPlayer.team = 2;
            } else {
                myPlayer.team = 1;
            }
            for (var i = 0; i < activeHost.players.length; i++) {
                if (activeHost.players[i].id === currentPlayer().id) {
                    activeHost.players[i] = myPlayer;
                }
            }

            await updateDoc(roomRef, {
            players: activeHost.players
            });
        }
    };

    const startRoundRobinGame = () => {
        const roomRef = doc(db, "hosts", roomKey);

        console.log('GAME IS STARTING');

        console.log('Shuffle Cards');
        // Shuffle the cards
        const newChallenges = shuffle(freshChallengeCards);
        const newSolutions = shuffle(freshSolutionCards);

        console.log('Distribute Cards');
        // Distribute the cards
        const playersToUpdate = activeHost.players
        playersToUpdate.forEach((player) => {
            var solutionsToAdd = [];
            var challengesToAdd = [];
            console.log(`SOLUTIONS TO ADD: ${solutionsToAdd.length}`);
            for (var i = 0; i < 3; i++) {
                // Solutions
                if (solutionsToAdd.length == 0) {
                    solutionsToAdd = [newSolutions.pop()];
                    console.log(`SOLUTIONS TO ADD: ${solutionsToAdd}`);
                } else {
                    solutionsToAdd.push(newSolutions.pop());
                    console.log(`SOLUTIONS TO ADD: ${solutionsToAdd}`);
                }
                // Challenges
                if (challengesToAdd.length == 0) {
                    challengesToAdd = [newChallenges.pop()];
                } else {
                    challengesToAdd.push(newChallenges.pop());
                }
            }
            player.currentSolutions = solutionsToAdd;
            player.currentChallenges = challengesToAdd;
        })

        updateDoc(roomRef, {
            currentPlayer: activeHost.players[0],
            players: playersToUpdate,
            requirementAmount: 1,
            currentState: "playing",
            remainingChallenges: newChallenges,
            remainingSolutions: newSolutions
        });
    };

    const startPollParty = () => {
        const roomRef = doc(db, "hosts", roomKey);

        // Shuffle the cards
        const newChallenges = shuffle(freshChallengeCards);
        const newSolutions = shuffle(freshSolutionCards);

        const round1Solutions = newSolutions.slice(0, 8);
        const round2Solutions = newSolutions.slice(8, 16);
        const round3Solutions = newSolutions.slice(16, 24);

        updateDoc(roomRef, {
            currentState: "polling",
            timeRemaining: 75,
            currentRound: 1,
            ranksToFind: [1, 2, 3, 4],
            round1Challenge: newChallenges[0],
            round2Challenge: newChallenges[1],
            round3Challenge: newChallenges[2],
            round1Solutions: round1Solutions,
            round2Solutions: round2Solutions,
            round3Solutions: round3Solutions
        });
    };

    const skipTutorial = () => {
        if (roomKey != "") {
            if (activeHost?.gameType === "pollparty") {
                startPollParty();
            } else {
                startRoundRobinGame();
            }
        }
    };

    const fetchCurrentGame = async () => {
        try {
            const roomCode = cookies.get('roomCode').toUpperCase();
            console.log(`ROOM CODE: ${roomCode}`);
            console.log(`MY ID: ${cookies.get('myId')}`);
            const q = query(collection(db, "hosts"), where("code", "==", roomCode), where("active", "==", true));
            const doc = await getDocs(q);
            const data = doc.docs[0].data();
            setRoomKey(doc.docs[0].id);
            console.log(data)
            setActiveHost(data);
            if (activeHost == null) {
                setIsVIP(false);
            }
            setIsVIP(cookies.get('myId') == "0");

        } catch (err) {
            console.log("FAILED TO GET ACTIVE HOST")
            openRoomClosed();
            console.error(err);
            // alert("An error occured while fetching user data");
        }
    };

    function shuffle(array) {
        let currentIndex = array.length, randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex > 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
        
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]
            ];
        }
        return array;
    };

    function currentPlayer() {
        if (activeHost == null) {
            return {name: "", avatar: "1", color: '#000000'};
        } else {
            const player = activeHost?.players?.filter((p => {
                return p.id == cookies.get('myId');
            }))[0];
            return player;
        }
    };

    function playerCards() {
        if (activeHost == null) {
            return [];
        } else {
            const player = currentPlayer();
            const finalSolutions = solutionCards.filter((card) => {
                return player?.currentSolutions?.includes(card.data().id);
            })
            const finalChallenges = challengeCards.filter((card) => {
                return player?.currentChallenges?.includes(card.data().id);
            })

            return finalChallenges.concat(finalSolutions);
        }
    }

    const fetchSolutions = async () => {
        try {
            console.log("solutions");
            const q = query(collection(db, "solutions"));
            const doc = await getDocs(q);
            const myCards = doc.docs;
            console.log(myCards);
            if (myCards != null) {
                const sortedCards = myCards.sort((a, b) => a.data().id < b.data().id ? -1 : 1)
                setSolutionCards(sortedCards);
            } else {
                console.log("Missing Card Data");
                return;
            }
        } catch (err) {
            console.log("An error occured while fetching card data");
            console.error(err);
        }
    };
  
    const fetchChallenges = async () => {
        try {
            const q = query(collection(db, "challenges"));
            const doc = await getDocs(q);
            const myCards = doc.docs;
            if (myCards != null) {
                const sortedCards = myCards.sort((a, b) => a.data().id < b.data().id ? -1 : 1)
                setChallengeCards(sortedCards);
            } else {
                console.log("Missing Card Data");
                return;
            }
        } catch (err) {
            console.log("An error occured while fetching card data");
            console.error(err);
        }
    };

    function fetchAvailableSlots(card) {
        var startingSlots = [];
        if (!isCurrentPlayer) {
            return [];
        }
        if (activeHost?.requirementAmount >= 1) {
            if (card.id < 27) {
                if (activeHost?.slot1 == null) {
                    startingSlots.push(1);
                }
            } else {
                if ((activeHost?.slot2 == null) && (activeHost?.slot1 != null)) {
                    startingSlots.push(2);
                }
            }
        };
        if (activeHost?.requirementAmount >= 2) {
            if (card.id < 27) {
                if (activeHost?.slot3 == null) {
                    startingSlots.push(3);
                }
            } else {
                if (activeHost?.slot4 == null && activeHost?.slot3 != null) {
                    startingSlots.push(4);
                }
            }
        };
        if (activeHost?.requirementAmount >= 3) {
            if (card.id < 27) {
                if (activeHost?.slot5 == null) {
                    startingSlots.push(5);
                }
            } else {
                if ((activeHost?.slot6 == null) && (activeHost?.slot5 != null)) {
                    startingSlots.push(6);
                }
            }
        };
        if (activeHost?.requirementAmount >= 4) {
            if (card.id < 27) {
                if (activeHost?.slot7 == null) {
                    startingSlots.push(7);
                }
            } else {
                if ((activeHost?.slot8 == null) && (activeHost?.slot7 != null)) {
                    startingSlots.push(8);
                }
            }
        };
        if (activeHost?.requirementAmount >= 5) {
            if (card.id < 27) {
                if (activeHost?.slot9 == null) {
                    startingSlots.push(9);
                }
            } else {
                if ((activeHost?.slot10 == null) && (activeHost?.slot9 != null)) {
                    startingSlots.push(10);
                }
            }
        };
        return startingSlots;
    }

    function playCardInSlot(cardId, slot) {
        // Send Card Data
        const roomRef = doc(db, "hosts", roomKey);
        const newHistory = activeHost?.history ?? []
        const newItem = {
            "cardId": `${cardId}`, 
            "playerName": `${activeHost.currentPlayer?.name ?? "empty"}`, 
            "playerId": `${activeHost.currentPlayer?.id ?? 999}`, 
            "userId": `${user?.id ?? "none"}`,
            "slot": `${slot}`,
            "rank": `${activeHost?.currentRank ?? 1}`};
        newHistory.push(newItem);
        updateDoc(roomRef, {
           history: newHistory 
        });
        if (slot == 1) {
            updateDoc(roomRef, {
                slot1: cardId
            });
        } else if (slot == 2) {
            updateDoc(roomRef, {
                slot2: cardId
            });
        } else if (slot == 3) {
            updateDoc(roomRef, {
                slot3: cardId
            });
        } else if (slot == 4) {
            updateDoc(roomRef, {
                slot4: cardId
            });
        } else if (slot == 5) {
            updateDoc(roomRef, {
                slot5: cardId
            });
        } else if (slot == 6) {
            updateDoc(roomRef, {
                slot6: cardId
            });
        } else if (slot == 7) {
            updateDoc(roomRef, {
                slot7: cardId
            });
        } else if (slot == 8) {
            updateDoc(roomRef, {
                slot8: cardId
            });
        } else if (slot == 9) {
            updateDoc(roomRef, {
                slot9: cardId
            });
        } else if (slot == 10) {
            updateDoc(roomRef, {
                slot10: cardId
            });
        }
        // Remove Card from player hand
        var myPlayer = currentPlayer();
        if (cardId < 27) {
            const index = myPlayer.currentChallenges.indexOf(cardId);
            const x = myPlayer.currentChallenges.splice(index, 1);
            if (activeHost.remainingChallenges.length == 0) {
                activeHost.remainingChallenges = freshChallengeCards;
            }
            myPlayer.currentChallenges.push(activeHost.remainingChallenges.pop());
        } else {
            const index = myPlayer.currentSolutions.indexOf(cardId);
            const x = myPlayer.currentSolutions.splice(index, 1);
            if (activeHost.remainingSolutions.length == 0) {
                activeHost.remainingSolutions = freshSolutionCards;
            }
            myPlayer.currentSolutions.push(activeHost.remainingSolutions.pop());
        }
        for (var i = 0; i < activeHost.players.length; i++) {
            if (activeHost.players[i].id === myPlayer.id) {
                activeHost.players[i] = myPlayer;
            }
        }
        updateDoc(roomRef, {
            players: activeHost.players,
            remainingChallenges: activeHost.remainingChallenges,
            remainingSolutions: activeHost.remainingSolutions
        });
        // Set the next player in the list as the current player
        var newIndex = activeHost.currentPlayer.id + 1;
        if (newIndex >= activeHost.players.length) {
            newIndex = 0;
        }
        updateDoc(roomRef, {
            currentPlayer: activeHost.players[newIndex]
        });
    };

    function continueClicked() {
        const roomRef = doc(db, "hosts", roomKey);
        updateDoc(roomRef, {
            vipNextNeeded: false,
            currentState: 'nextRank'
        });
    };

    function nextPlayer() {
        const roomRef = doc(db, "hosts", roomKey);
        // Set the next player in the list as the current player
        var newIndex = activeHost.currentPlayer.id + 1;
        if (newIndex >= activeHost.players.length) {
            newIndex = 0;
        }
        updateDoc(roomRef, {
            currentPlayer: activeHost.players[newIndex]
        });
    };

    function endGameTapped() {
        const roomRef = doc(db, "hosts", roomKey);
        updateDoc(roomRef, {
            active: false
        });
        cookies.remove('roomCode');
        cookies.remove('myName');
        cookies.remove('myId');
        navigate('/');
    };

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

    useEffect(() => {
        if (loading) return;

        fetchCurrentGame();
        fetchChallenges();
        fetchSolutions();
        setName(cookies.get('myName'));
        const roomCode = cookies.get('roomCode').toUpperCase();
        console.log(`ROOM CODE: ${roomCode}`);
        const q = query(collection(db, "hosts"), where("code", "==", roomCode), where("active", "==", true));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                console.log(`ACTIVE HOST ${change.doc.data()}`);
                if (!change.doc.data().active) {
                    openRoomClosed();
                }
                setActiveHost(change.doc.data());
                setRoomKey(change.doc.id);
                if ((change.doc.data()?.currentPlayer?.id ?? '999') === cookies.get('myId')) {
                    setIsCurrentPlayer(true);
                } else {
                    setIsCurrentPlayer(false);
                }
            });
        });
    }, [user, loading]);
    return (
        <div style={{background: '#333333', minHeight: '1200px'}}>
        <div>
            <div style={{background: `${isCurrentPlayer ? `linear-gradient(180deg, ${currentPlayer().color}50, #333333)` : '#333333'}`}}>
                <div style={{width: '100%', background: '#FFFFFF70'}}>
                    <div style={{display: 'flex', margin: '0 auto', width: 'fit-content', alignItems: 'center'}}>
                        <img style={{objectFit: "contain", height: '50px', margin: '0 auto', padding: '10px 0px', display: 'block'}} src={require(`../assets/images/avatar${currentPlayer()?.avatar ?? 1}.png`)} />
                        <h2 className="survey-title" style={{textAlign: 'center', marginLeft: '8px', textTransform: 'lowercase'}}>{currentPlayer().name}</h2>
                    </div>
                </div>
                {(activeHost?.gameType === 'pollparty')? (
                    <div>
                        <div>
                            <p style={{textAlign: "center", color: '#FFFFFF', marginTop: '0px', fontSize: '20px', background: PURPLE, padding: '8px'}}><b>{(currentPlayer()?.team ?? 1) === 1 ? `${activeHost?.team1Name ?? "team 1"}` : `${activeHost?.team2Name ?? "team 2"}`}</b></p>
                        </div>
                        <PlayerWaitingRoomPollParty style={{display: `${activeHost?.currentState === 'waitingRoom' ? '' : 'none'}`}} roomKey={roomKey} activeHost={activeHost} currentPlayer={currentPlayer()} onClickChangeTeam={() => {switchTeam()}}/>
                        <PlayerTutorial style={{display: `${activeHost?.currentState === 'tutorial' ? '' : 'none'}`}} currentPlayer={currentPlayer()} activeHost={activeHost} isVIP={isVIP} onClick={() => {skipTutorial()}}/>
                        <PlayerPlayingPollParty roomKey={roomKey} currentPlayer={currentPlayer()} isCurrentPlayer={isCurrentPlayer} activeHost={activeHost} challenges={challengeCards} solutions={solutionCards} isVIP={isVIP} onClick={() => {skipTutorial()}}/>
                    </div>
                ) : (
                    <div>
                        <PlayerWaitingRoom style={{display: `${activeHost?.currentState === 'waitingRoom' ? '' : 'none'}`}} roomKey={roomKey} activeHost={activeHost} currentPlayer={currentPlayer()} onClick={() => {attemptToStartGame()}}/>
                        <PlayerTutorial style={{display: `${activeHost?.currentState === 'tutorial' ? '' : 'none'}`}} currentPlayer={currentPlayer()} activeHost={activeHost} isVIP={isVIP} onClick={() => {skipTutorial()}}/>
                        <PlayerPlaying style={{display: `${((activeHost?.currentState === 'playing') || activeHost?.currentState === 'waitingForVIPToProceed' || activeHost?.currentState === 'nextProject') ? '' : 'none'}`}} activeHost={activeHost} isVIP={isVIP} isCurrentPlayer={isCurrentPlayer} cards={playerCards()} onClick={(card) => {
                            setSelectedCard(card);
                            setOverlayOpen(true);
                        }} onEndGameClicked={() => {
                            endGameTapped();
                        }} onContinueClicked={() => {
                            continueClicked();
                        }} skipPlayerClicked={() => {
                            nextPlayer();
                        }}/>
                    </div>
                )}
                
            </div>
        </div>
        {isOverlayOpen && (
            <PlayerPlayCardOverlay onClose={closeOverlay}>
            <div className="scrollable-content" style={{width: '100%'}}>
                {/* Your scrollable content goes here */}
                <div style={{margin: '16px auto', width: 'fit-content'}}>
                    <ProTalkCard
                        card={selectedCard}
                        />
                </div>
                {(fetchAvailableSlots(selectedCard).length == 0)? (
                    <a className="final-submit-btn gray-btn" style={{marginTop: '8px'}} onClick={() => {
                        setOverlayOpen(false);
                    }}>{`${isCurrentPlayer ? 'try a different card' : 'wait for your turn'}`}</a>
                ) : (
                    <div>
                        {fetchAvailableSlots(selectedCard).map(slot => (
                            <a className="final-submit-btn green-btn-dash" style={{marginTop: '8px'}} onClick={() => {
                                console.log(`Slot Selected: ${slot}`)
                                playCardInSlot(selectedCard.id, slot);
                                setOverlayOpen(false);
                                scrollToTop();
                            }}>{`play in slot ${slot}`}</a>
                        ))}
                    </div>
                )}
                
            </div>
            </PlayerPlayCardOverlay>
        )}
        {roomClosed && (
            <PlayerPlayCardOverlay onClose={closeOverlay}>
            <div className="scrollable-content" style={{width: '100%'}}>
                {/* Your scrollable content goes here */}
                <div>
                    <h3 style={{textAlign: 'center'}}>your game has ended</h3>
                    <a className="final-submit-btn blue-btn" style={{marginTop: '8px'}} onClick={() => {
                        cookies.remove('roomCode');
                        cookies.remove('myName');
                        cookies.remove('myId');
                        navigate("/");
                    }}>go back home</a>
                </div>
                
            </div>
            </PlayerPlayCardOverlay>
        )}
        </div>
    );
}

export default Player;