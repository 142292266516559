import React, { useRef, useEffect, useState } from "react";
import "../App.css";

class HostTutorial extends React.Component {  
    render() {
        const { activeHost, onClick, style } = this.props;

        // Colors
        const red = "#C65447";
        const purple = "#805CE8";
        const purpleBackground = "#805CE820";
        const green = "#409F91";
        const greenBackground = "#409F9120";
        const blue = "#68A6EB";
        const yellow = "#E8C352";
        const orange = "#DA6E2C";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D5";

        const animationData = require(`../assets/images/host-waiting.json`);

        return (
            <div style={style}>
                {(activeHost?.gameType === 'pollparty')?(
                    <div>
                        {/* <img style={{objectFit: "contain", margin: '24px auto', height: '13vw', width: '13vw', display: 'block'}} src={require(`../assets/images/avatar5.png`)} /> */}
                        <h1 className="survey-title" style={{textAlign: 'center', fontSize: '3vw', marginTop: '24px', marginBottom: '0px'}}>let's play <b><span style={{color: purple}}>y</span><span style={{color: red}}>i</span><span style={{color: yellow}}>k</span><span style={{color: green}}> y</span><span style={{color: orange}}>a</span><span style={{color: blue}}>k</span></b></h1>
                        <div style={{margin: '16px 0px 24px 0px'}}>
                            <h1 className="survey-title" style={{textAlign: 'center', margin: '0px auto', fontSize: '2.2vw', width: 'fit-content', padding: '16px 40px', borderRadius: '50px', color: `${activeHost?.gameType === "standard" ? green : purple}`, background: `${activeHost?.gameType === "standard" ? greenBackground : purpleBackground}`}}>{activeHost?.gameType === "standard" ? "round robin 🔄" : "polling party 🎉"}</h1>
                        </div>
                        <a className="final-submit-btn green-btn-dash" style={{padding: '12px', marginBlock: '0px'}} onClick={() => {onClick('1')}}>Start Game</a>
                        <h2 className="survey-title" style={{textAlign: 'center', fontSize: '2vw', fontWeight: '400'}}>your team's goal is collect the most <span style={{color: yellow, fontWeight: '600'}}>coins</span></h2>
                        <h2 className="survey-title" style={{textAlign: 'center', fontSize: '1.3vw', fontWeight: '400'}}>your game will be broken down into 3 rounds</h2>
                        <div style={{display: 'flex'}}>
                            <div>
                                <img style={{objectFit: "contain", margin: '24px auto', height: '150px', width: 'auto', display: 'block'}} src={require(`../assets/images/avatar1.png`)} />
                                <h1 className="survey-title" style={{textAlign: 'text-start', fontSize: '2.2vw', fontWeight: '600'}}>round 1</h1>
                                <p className="survey-title" style={{textAlign: 'text-start', fontSize: '1.2vw', fontWeight: '400'}}>
                                    <b>by yourself:</b> choose the 4 solutions you feel best beat the current challenge and rank them from <i>best</i> to <i>worst</i>
                                </p>
                                <p className="survey-title" style={{textAlign: 'text-start', fontSize: '1.2vw', fontWeight: '400'}}>
                                    <b>as a team:</b> your goal is to identify which solutions all players collectively ranked as <span style={{fontWeight: '600', color: green}}>1st</span>, <span style={{fontWeight: '600', color: blue}}>2nd</span>, <span style={{fontWeight: '600', color: orange}}>3rd</span>, and <span style={{fontWeight: '600', color: red}}>4th</span>.
                                </p>
                            </div>
                            <div>
                                <img style={{objectFit: "contain", margin: '24px auto', height: '150px', width: 'auto', display: 'block'}} src={require(`../assets/images/avatar3.png`)} />
                                <h1 className="survey-title" style={{textAlign: 'text-start', fontSize: '2.2vw', fontWeight: '600', marginLeft: '16px', marginRight: '16px'}}>round 2</h1>
                                <p className="survey-title" style={{textAlign: 'text-start', fontSize: '1.2vw', fontWeight: '400', marginLeft: '16px', marginRight: '16px'}}>
                                    <b>by yourself:</b> choose the 5 solutions you feel best beat the current challenge and rank them from <i>best</i> to <i>worst</i>                                    
                                </p>
                                <p className="survey-title" style={{textAlign: 'text-start', fontSize: '1.2vw', fontWeight: '400', marginLeft: '16px', marginRight: '16px'}}>
                                    <b>as a team:</b> your goal is to identify which solutions the group collectively ranked as <span style={{fontWeight: '600'}}>not the 1st choice</span>, but <span style={{fontWeight: '600', color: blue}}>2nd</span>, <span style={{fontWeight: '600', color: yellow}}>3rd</span>, <span style={{fontWeight: '600', color: orange}}>4th</span>, & <span style={{fontWeight: '600', color: red}}>5th</span>.
                                </p>
                            </div>
                            <div>
                                <img style={{objectFit: "contain", margin: '24px auto', height: '150px', width: 'auto', display: 'block'}} src={require(`../assets/images/avatar6.png`)} />
                                <h1 className="survey-title" style={{textAlign: 'text-start', fontSize: '2.2vw', fontWeight: '600'}}>round 3</h1>
                                <p className="survey-title" style={{textAlign: 'text-start', fontSize: '1.2vw', fontWeight: '400'}}>
                                    <b>by yourself:</b> choose the 6 solutions you feel best beat the current challenge and rank them from <i>best</i> to <i>worst</i>.
                                </p>
                                <p className="survey-title" style={{textAlign: 'text-start', fontSize: '1.2vw', fontWeight: '400'}}>
                                    <b>as a team:</b> your goal is to identify which solutions the group collectively ranked from <span style={{fontWeight: '600', color: red}}>8th</span> to <span style={{fontWeight: '600', color: green}}>1st</span>.
                                </p>
                            </div>
                        </div>
                        <p className="survey-title" style={{textAlign: 'center', fontSize: '1.8vw', fontWeight: '600'}}>
                            each time your team correctly identifies a solution in the right spot<br></br>
                            your team will earn <span style={{fontWeight: '600', color: yellow}}>one coin</span>!
                        </p>
                    </div>
                ) : (
                    <div>
                        <h2 className="survey-title" style={{textAlign: 'center', fontSize: '32px', fontWeight: '500'}}>let's get started</h2>
                        <video width="75%" height="50%" style={{margin: '0 auto', display: 'block'}}>
                            <source src={'https://firebasestorage.googleapis.com/v0/b/pro-talk-game.appspot.com/o/HowToPlayProTalk.mp4?alt=media&token=776fe4d3-8989-4412-aa98-b3aaf19e6433'} />
                        </video>
                        <p style={{textAlign: 'center', fontSize: '24px', fontWeight: '500'}}>already know how to play? the vip can skip at any time</p>
                    </div>     
                )}
            </div>
        );
    }
}
  
export default HostTutorial;