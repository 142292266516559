import React from "react";
import "../../App.css";
import ProTalkCard from "../../resources/ProTalkCard";
import { GREEN, YELLOW, ORANGE, RED } from "../../components/Colors";
import PartyRankCell from "./PartyRankCell";

class HostPlayingPollParty extends React.Component {
    render() {
        const { activeHost, solutions, challenges, onClick, currentTime, secondsRemaining } = this.props;

        // Colors
        const red = "#C65447";
        const purple = "#805CE8";
        const purpleBackground = "#805CE820";
        const green = "#409F91";
        const greenBackground = "#409F9120";
        const blue = "#68A6EB";
        const yellow = "#E8C352";
        const orange = "#DA6E2C";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D5";

        function fetchRoundChallenge() {
            if (challenges != null) {
                switch (activeHost?.currentRound) {
                case 1:
                    var foundChallenge = challenges?.filter((c => { return Number(c.id) === Number(activeHost?.round1Challenge ?? 1) }))[0];
                    console.log(`matching challenge ${foundChallenge.data().name}`);
                    return foundChallenge.data();
                case 2:
                    var foundChallenge = challenges?.filter((c => { return Number(c.id) === Number(activeHost?.round2Challenge ?? 1) }))[0];
                    console.log(`matching challenge ${foundChallenge.data().name}`);
                    return foundChallenge.data();
                case 3:
                    var foundChallenge = challenges?.filter((c => { return Number(c.id) === Number(activeHost?.round3Challenge ?? 1) }))[0];
                    console.log(`matching challenge ${foundChallenge.data().name}`);
                    return foundChallenge.data();
                default:
                    return null;
                }
            } else {
                return null;
            }
        }

        function fetchRankRequirement() {
            if (activeHost?.currentRound === 1) {
                return 4;
            } else if (activeHost?.currentRound === 2) {
                return 5;
            } else {
                return 6;
            }
        }

        function fetchTurnInstructions() {
            if (activeHost?.currentRound === 1) {
                return `can you and your team identify any of following ranks? ${activeHost.ranksToFind}`;
            } else if (activeHost?.currentRound === 2) {
                return `can you and your team identify any of following ranks? ${activeHost.ranksToFind}?`;
            } else if (activeHost?.currentRound === 3) {
                return `can you and your team identify the solution the entire group ranked as ${activeHost?.ranksToFind[0]}?`;
            }
        }

        function winningTeamString() {
            if ((activeHost?.team1Coins ?? 0) > (activeHost?.team2Coins ?? 0)) {
                return 'team 1 wins!'
            } else if ((activeHost?.team2Coins ?? 0) > (activeHost?.team1Coins ?? 0)) {
                return 'team 2 wins!'
            } else {
                return 'draw!'
            }
        }

        function coinString(team) {
            if (team === 1) {
                if ((activeHost?.team1Coins ?? 0) === 1) {
                    return '1 coin'
                } else {
                    return `${activeHost?.team1Coins ?? 0} coins`;
                }
            } else {
                if ((activeHost?.team2Coins ?? 0) === 1) {
                    return '1 coin'
                } else {
                    return `${activeHost?.team2Coins ?? 0} coins`;
                }
            }
        }

        return (
            <div style={{display: `${((activeHost?.currentState === 'polling' || activeHost?.currentState === 'party')) ? '' : 'none'}`}}>
                {/* <h2 className="survey-title" style={{textAlign: 'center', background: '#33333320', padding: '8px', borderRadius: '10px'}}>{`current rank ${activeHost?.currentRank ?? 1}`}</h2> */}
                <div style={{display: 'flex', width: '-webkit-fill-available', height: '-webkit-fill-available'}}>
                    <div style={{background: '#00000005', width: '30%', height: '100%'}}>
                        <h1 style={{textAlign: 'center'}}>{activeHost?.team1Name ?? 'team 1'}</h1>
                        <h2 style={{textAlign: 'center', fontSize: '3vw', marginBlock: '0px', color: yellow}}>{coinString(1)}</h2>
                        {/* TEAM 1 */}
                        <div>
                            <div style={{display: 'flex', margin: '48px 2vw', flexWrap: 'wrap', gap: '0.5vw', rowGap: '1vw'}}>
                                {activeHost?.players?.filter(p => ((p.team ?? 1) === 1))?.map(player => (
                                    <div key={player.id}>
                                        <img style={{objectFit: "contain", height: '50px', margin: '0 auto', display: 'block'}} src={require(`../../assets/images/avatar${(player.avatar)}.png`)} />
                                        <h2 className="survey-title" style={{color: `${player.color}`, textAlign: 'center', marginBottom: '6px', fontWeight: '600', textTransform: 'lowercase', fontSize: '2vw', marginTop: '0px'}}>{player.name}</h2>
                                        {(player.isVIP)? (
                                            <div>
                                                <p className="survey-title" style={{padding: '3px 16px', color: gold, background: goldBackground, width: 'fit-content', margin: '0 auto', borderRadius: '3px', fontSize: '1.2vw'}}>vip</p>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div style={{background: '#33333310', width: '-webkit-fill-available', height: '100%'}}>
                        <h1 style={{textAlign: 'center'}}>round {activeHost?.currentRound ?? 999} challenge</h1>
                        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                            <div style={{margin: '0 auto', width: 'fit-content', borderRadius: '24px'}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <img style={{objectFit: "contain", height: '15vw', display: 'block', filter: 'drop-shadow(1px 5px 5px #00000080)'}} src={require(`../../assets/images/${(fetchRoundChallenge()?.id ?? 1)}.png`)} />
                                    <div style={{maxWidth: '22vw'}}>
                                        <p style={{color: 'black', textAlign: 'textstart', padding: '0px 24px 0px 24px', fontSize: '3.5vw', fontWeight: '600', marginBlock: '0px'}}>{fetchRoundChallenge()?.name}</p>
                                        <p style={{color: '#666666', textAlign: 'textstart', padding: '8px 24px 12px 24px', fontSize: '1.1vw', fontWeight: '400', fontStyle: 'italic', marginBlock: '0px'}}>{fetchRoundChallenge()?.subtitle}</p>
                                    </div>
                                </div>
                                
                                <p style={{color: '#666666', textAlign: 'center', fontWeight: '600', padding: '12px 24px 8px 24px', marginBlock: '0px', fontSize: '1.2vw'}}>example scenario</p>
                                <p style={{color: 'black', textAlign: 'center', padding: '0px 24px 12px 24px', fontSize: '18px', fontWeight: '400', marginBlock: '0px'}}>{fetchRoundChallenge()?.kidDescription}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{background: '#00000005', width: '30%', height: '100%'}}>
                        <h1 style={{textAlign: 'center'}}>{activeHost?.team2Name ?? 'team 2'}</h1>
                        <h2 style={{textAlign: 'center', fontSize: '3vw', marginBlock: '0px', color: yellow}}>{coinString(2)}</h2>
                        {/* TEAM 2 */}
                        <div>
                        <div style={{display: 'flex', margin: '48px 2vw', flexWrap: 'wrap', gap: '0.5vw', rowGap: '1vw'}}>
                                {activeHost?.players?.filter(p => ((p.team ?? 1) === 2))?.map(player => (
                                    <div key={player.id}>
                                        <img style={{objectFit: "contain", height: '50px', margin: '0 auto', display: 'block'}} src={require(`../../assets/images/avatar${(player.avatar)}.png`)} />
                                        <h2 className="survey-title" style={{color: `${player.color}`, textAlign: 'center', marginBottom: '6px', fontWeight: '600', textTransform: 'lowercase', fontSize: '2vw', marginTop: '0px'}}>{player.name}</h2>
                                        {(player.isVIP)? (
                                            <div>
                                                <p className="survey-title" style={{padding: '3px 16px', color: gold, background: goldBackground, width: 'fit-content', margin: '0 auto', borderRadius: '3px', fontSize: '1.2vw'}}>vip</p>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {(activeHost?.currentState === 'polling') ? (
                    <div>
                        <p style={{textAlign: 'center', fontSize: '2.2vw'}}>use your device to rank the {fetchRankRequirement()} solutions you feel beat this challenge from <span style={{fontWeight: '600', color: green}}>best</span> to <span style={{fontStyle: 'italic', fontWeight: '600', color: red}}>worst</span></p>
                        {/* <p style={{textAlign: 'center', fontSize: '2vw'}}>time remaining<br></br><span style={{fontWeight: '600', fontSize: '5vw'}}>{secondsRemaining ?? 99}</span></p> */}
                    </div>
                ) : (
                    <div>
                        {((activeHost?.ranksToFind?.length ?? 0) === 0)? (
                            <div>
                            {(activeHost?.currentRound === 3)? (
                                <div>
                                    <p style={{textAlign: 'center', fontSize: '2.8vw', fontWeight: '600', marginBlock: '0px', margin: '24px 0'}}>game complete! 🎉</p>
                                    <p style={{textAlign: 'center', fontSize: '2vw', fontWeight: '500', marginBlock: '0px', marginBottom: '32px'}}>{winningTeamString()}</p>
                                </div>
                            ) : (
                                <div>
                                    <p style={{textAlign: 'center', fontSize: '2.8vw', fontWeight: '600', marginBlock: '0px', margin: '24px 0'}}>round {activeHost?.currentRound} complete! 🎉</p>
                                    <p style={{textAlign: 'center', fontSize: '2vw', fontWeight: '500', marginBlock: '0px', marginBottom: '16px'}}>discuss what you learned while completing this round!<br></br>tap 'continue' to move to the next round.</p>
                                    <a className="final-submit-btn green-btn-dash" style={{padding: '12px', marginBlock: '0px', marginBottom: '32px'}} onClick={() => {onClick('1')}}>Continue</a>
                                </div>
                            )}
                            </div>
                        ) : (
                            <div>
                                {/* CURRENT PLAYER MODULE */}
                                <div style={{display: 'flex', margin: '16px auto', width: 'fit-content', alignItems: 'center', background: `${activeHost?.currentPlayer?.color ?? '#000000'}20`, borderRadius: '10px', padding: '0px 24px'}}>
                                    <img style={{objectFit: "contain", height: '60px', margin: '0 auto', display: 'block'}} src={require(`../../assets/images/avatar${activeHost?.currentPlayer?.avatar ?? 1}.png`)} />
                                    <h1 className="survey-title" style={{textAlign: 'center', marginLeft: '8px', textTransform: 'lowercase'}}><span style={{color: `${activeHost?.currentPlayer?.color ?? '#000000'}`}}>{activeHost?.currentPlayer?.name ?? 'player'}'s</span> turn</h1>
                                </div>
                                <p style={{textAlign: 'center', fontSize: '1.8vw'}}>{fetchTurnInstructions()}</p>
                            </div>
                        )}
                        <div style={{display: 'flex', justifyContent: 'space-around', gap: '4px', flexWrap: 'nowrap', flexDirection: 'row', alignItems: 'center'}}>
                            <PartyRankCell rank={1} host={activeHost} solutions={solutions}/>
                            <PartyRankCell rank={2} host={activeHost} solutions={solutions}/>
                            <PartyRankCell rank={3} host={activeHost} solutions={solutions}/>
                            <PartyRankCell rank={4} host={activeHost} solutions={solutions}/>
                            <PartyRankCell rank={5} host={activeHost} solutions={solutions}/>
                            <PartyRankCell rank={6} host={activeHost} solutions={solutions}/>
                            <PartyRankCell rank={7} host={activeHost} solutions={solutions}/>
                            <PartyRankCell rank={8} host={activeHost} solutions={solutions}/>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
  
export default HostPlayingPollParty;