import React from "react";
import "../App.css";
import ProTalkCard from "../resources/ProTalkCard";
import RankCard from "../resources/RankCard";

class HostPlaying extends React.Component {
    render() {
        const { activeHost, onClick, style, currentTime, slot1, slot2, slot3, slot4, slot5, slot6, slot7, slot8, slot9, slot10 } = this.props;

        const allSlots = [slot1, slot2, slot3, slot4, slot5, slot6, slot7, slot8, slot9, slot10];

        function getAvailableSlots() {
            if (activeHost?.requirementAmount === 2) {
                return [0, 1, 2, 3];
            }
            if (activeHost?.requirementAmount === 3) {
                return [0, 1, 2, 3, 4, 5];
            }
            if (activeHost?.requirementAmount === 4) {
                return [0, 1, 2, 3, 4, 5, 6, 7];
            }
            if (activeHost?.requirementAmount === 5) {
                return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            }
            return [0, 1];
        }

        return (
            <div style={style}>
                {/* <h2 className="survey-title" style={{textAlign: 'center', background: '#33333320', padding: '8px', borderRadius: '10px'}}>{`current rank ${activeHost?.currentRank ?? 1}`}</h2> */}
                <h1></h1>
                <RankCard rank={activeHost?.currentRank ?? 1} numberOfCardsRequired={activeHost?.requirementAmount ?? 1} />
                {(activeHost?.currentState == "waitingForVIPToProceed")? (
                    <div>
                        <h1 className="survey-title" style={{textAlign: 'center'}}>you completed the rank 🎉</h1>
                        <h3 className="score-text" style={{textAlign: 'center', marginBottom: '48px', color: 'gray'}}>discuss what you learned while completing this rank! the vip can tap 'continue' to move to the next rank.</h3>
                    </div>
                ) : (
                    <div style={{display: 'flex', margin: '16px auto', width: 'fit-content', alignItems: 'center', background: `${activeHost?.currentPlayer?.color ?? '#000000'}20`, borderRadius: '10px', padding: '0px 24px'}}>
                        <img style={{objectFit: "contain", height: '60px', margin: '0 auto', display: 'block'}} src={require(`../assets/images/avatar${activeHost?.currentPlayer?.avatar ?? 1}.png`)} />
                        <h1 className="survey-title" style={{textAlign: 'center', marginLeft: '8px', textTransform: 'lowercase'}}><span style={{color: `${activeHost?.currentPlayer?.color ?? '#000000'}`}}>{activeHost?.currentPlayer?.name ?? 'player'}'s</span> turn</h1>
                    </div>
                )}
                <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '24px'}}>
                    <div style={{display: 'flex'}}>
                        <ProTalkCard 
                            card={allSlots[0]}
                            slotNumber={(1)}
                            isOverlayed={false}
                            onClick={() => {
                                // selectNewCard(card.data());
                            }}
                        />
                        <ProTalkCard 
                            card={allSlots[1]}
                            slotNumber={(2)}
                            isOverlayed={true}
                            onClick={() => {
                                // selectNewCard(card.data());
                            }}
                        />
                        {(activeHost?.requirementAmount >= 2)? (
                            <div style={{display: 'flex'}}>
                                <ProTalkCard 
                                    card={allSlots[2]}
                                    slotNumber={(3)}
                                    isOverlayed={false}
                                    onClick={() => {
                                        // selectNewCard(card.data());
                                    }}
                                />
                                <ProTalkCard 
                                    card={allSlots[3]}
                                    slotNumber={(4)}
                                    isOverlayed={true}
                                    onClick={() => {
                                        // selectNewCard(card.data());
                                    }}
                                />
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-evenly', marginBottom: '100px'}}>
                    <div style={{display: 'flex'}}>
                        {(activeHost?.requirementAmount >= 3)? (
                            <div style={{display: 'flex'}}>
                                <ProTalkCard 
                                card={allSlots[4]}
                                slotNumber={(5)}
                                isOverlayed={false}
                                onClick={() => {
                                    // selectNewCard(card.data());
                                }}
                                />
                                <ProTalkCard 
                                    card={allSlots[5]}
                                    slotNumber={(6)}
                                    isOverlayed={true}
                                    onClick={() => {
                                        // selectNewCard(card.data());
                                    }}
                                />
                            </div>
                        ):(
                            <div></div>
                        )}
                        {(activeHost?.requirementAmount >= 4)? (
                            <div style={{display: 'flex'}}>
                                <ProTalkCard 
                                card={allSlots[6]}
                                slotNumber={(6)}
                                isOverlayed={false}
                                onClick={() => {
                                    // selectNewCard(card.data());
                                }}
                                />
                                <ProTalkCard 
                                    card={allSlots[7]}
                                    slotNumber={(8)}
                                    isOverlayed={true}
                                    onClick={() => {
                                        // selectNewCard(card.data());
                                    }}
                                />
                            </div>
                        ):(
                            <div></div>
                        )}
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-evenly', marginBottom: '100px'}}>
                    <div style={{display: 'flex'}}>
                        {(activeHost?.requirementAmount >= 5)? (
                            <div style={{display: 'flex'}}>
                                <ProTalkCard 
                                card={allSlots[8]}
                                slotNumber={(9)}
                                isOverlayed={false}
                                onClick={() => {
                                    // selectNewCard(card.data());
                                }}
                                />
                                <ProTalkCard 
                                    card={allSlots[9]}
                                    slotNumber={(10)}
                                    isOverlayed={true}
                                    onClick={() => {
                                        // selectNewCard(card.data());
                                    }}
                                />
                            </div>
                        ):(
                            <div></div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
  
export default HostPlaying;