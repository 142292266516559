import React from "react";
import Lottie from "lottie-react";
import "../account/Account.css";
import LoadingComponent from "./LoadingComponent";

class ManagePlanCell extends React.Component {  
    render() {
        const { accountType, planRenewDate } = this.props;

        const green = "#409F91";
        const greenBackground = "#65b26c";
        const blue = "#1961A8";
        const blueBackground = "#D1DFEE20";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D520";

        return (
            <div>
                {(accountType === "")? (
                    <div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h4 style={{fontWeight: '500', color: 'white', margin: '24px 0 0 0', fontSize: '20px'}}>current plan</h4>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                            <LoadingComponent style={{height: '40px'}} />
                        </div>
                    </div>
                ) : (
                    <div>
                    {(accountType === "basic")? (
                        <div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h4 style={{fontWeight: '500', color: 'white', margin: '24px 0 0 0', fontSize: '20px'}}>current plan</h4>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '8px'}}>
                                <h1 style={{color: green, fontWeight: '600'}}>yik yak basic</h1>
                                <div style={{display: 'grid'}}>
                                    <a className="final-submit-btn green-btn-dash" href="/pricing" target="blank" style={{margin: "auto 16px auto auto"}}>upgrade</a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h4 style={{fontWeight: '500', color: 'white', margin: '24px 0 0 0', fontSize: '20px'}}>current plan</h4>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                                <h1 style={{color: green, fontWeight: '600'}}>yik yak basic</h1>
                                <div style={{display: 'grid'}}>
                                    <p className="score-text" style={{margin: "auto 16px auto auto"}}>renews on {planRenewDate}</p>
                                    <a className="final-submit-btn green-btn-dash" href="https://billing.stripe.com/p/login/aEU5mc7T9aIcdu8dQQ" target="blank" style={{margin: "auto 16px auto auto"}}>manage plan</a>
                                </div>
                            </div>
                        </div>
                    )}
                    </div>
                )}
            </div>
        );
    }
  }
  
export default ManagePlanCell;