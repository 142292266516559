import React from "react";
import "../../App.css";
import { GREEN, YELLOW, ORANGE, RED, BLUE, PURPLE, GRAY } from "../../components/Colors";

class PartyRankCell extends React.Component {  
    render() {
        const { rank, host, solutions } = this.props;

        function rankColor() {
            const round = host?.currentRound ?? 1
            const ranksToFind = host?.ranksToFind ?? [];
            switch (rank) {
                case 1:
                    if (ranksToFind.includes(1) || wasRevealed()) { 
                        return BLUE;
                    }
                    break
                case 2:
                    if (round === 1 || round === 2 || wasRevealed()) {
                        if (ranksToFind.includes(2) || wasRevealed()) { 
                            return GREEN;
                        }
                    } else {
                        if (ranksToFind.includes(2) || wasRevealed()) { 
                            return BLUE;
                        }
                    }
                    break
                case 3:
                    if (round === 1 || wasRevealed()) {
                        if (ranksToFind.includes(3) || wasRevealed()) { 
                            return ORANGE;
                        }
                    } else {
                        if (ranksToFind.includes(3) || wasRevealed()) { 
                            return GREEN;
                        }
                    }
                    break
                case 4:
                    if (round === 1) {
                        if (ranksToFind.includes(4) || wasRevealed()) { 
                            return RED;
                        }
                    } else if (round === 2) {
                        if (ranksToFind.includes(4) || wasRevealed()) { 
                            return ORANGE;
                        }
                    } else {
                        if (ranksToFind.includes(4) || wasRevealed()) { 
                            return GREEN;
                        }
                    }
                    break
                case 5:
                    if (ranksToFind.includes(5) || wasRevealed()) { 
                        return RED;
                    }
                    break
                case 6:
                    if (ranksToFind.includes(6) || wasRevealed()) { 
                        return ORANGE;
                    }
                    break
                case 7:
                    if (ranksToFind.includes(7) || wasRevealed()) { 
                        return RED;
                    }
                    break
                case 8:
                    if (ranksToFind.includes(8) || wasRevealed()) { 
                        return RED;
                    }
                    break
                default:
                    break
            }
            return GRAY;
        }

        function rankTitle() {
            switch (rank) {
                case 1:
                    return '1st';
                case 2:
                    return '2nd';
                case 3:
                    return '3rd';
                case 4:
                    return '4th';
                case 5:
                    return '5th';
                case 6:
                    return '6th';
                case 7:
                    return '7th';
                case 8:
                    return '8th';
                default:
                    return '';
            }
        }

        function fetchSolutionImage() {
            if (wasRevealed(host?.currentRound)) {
                const firstFoundSolution = solutions.filter((solution) => { 
                    return solution.id === roundRank()?.solution;
                })[0];
                return roundRank()?.solution ?? 44;
            } else {
                return 'placeholder'
            }
        }

        function wasRevealed() {
            const ranksToFind = host?.ranksToFind ?? [];
            return roundRank()?.revealed === true || ranksToFind.length === 0
        }

        function roundRank() {
            const round = host?.currentRound ?? 1;
            if (round === 1) {
                if (host?.round1Results != null) {
                    return host?.round1Results[rank - 1];
                }
            } else if (round === 2) {
                if (host?.round2Results != null) {
                    return host?.round2Results[rank - 1];
                }
            } else if (round === 3) {
                if (host?.round3Results != null) {
                    return host?.round3Results[rank - 1];
                }
            }
            return null;
        }

        function fetchSolutionTitle() {
            if (wasRevealed(host?.currentRound)) {
                const firstFoundSolution = solutions.filter((solution) => { 
                    return solution.id == roundRank()?.solution;
                })[0];
                return firstFoundSolution?.data()?.name ?? 'unknown';
            } else {
                return '. . .'
            }
        }

        return (
            <div style={{display: 'flex', width: '100%', border: `4px ${rankColor()}50 solid`, background: `${rankColor()}20`, borderRadius: '6px', cursor: 'pointer', minWidth: '5vw', minHeight: '5vw'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-evenly'}}>
                    <h1 style={{display: 'block', alignItems: 'center', textAlign: 'center', color: rankColor(), marginBlock: '0px', marginTop: '8px'}}>{rankTitle()}</h1>
                    <img style={{objectFit: "contain", height: '10vw', display: 'block', filter: 'drop-shadow(1px 5px 5px #22222240)'}} src={require(`../../assets/images/${(fetchSolutionImage())}.png`)} />
                    <h1 style={{display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '1.5vw', height: '100px', overflow: 'auto', textOverflow: 'ellipsis', color: rankColor(), marginBlock: '0px', marginBottom: '8px'}}>{fetchSolutionTitle()}</h1>
                </div>
            </div>
        );
    }
  }
  
export default PartyRankCell;