import React, { useRef, useEffect, useState } from "react";
import "../App.css";

class UpgradRequired extends React.Component {  
    render() {
        const { title, style, onClick } = this.props;

        // Colors
        const red = "#C65447";
        const redBackground = "#F4DDDA";
        const green = "#509E6F";
        const greenBackground = "#DCECE2";
        const blue = "#1961A8";
        const blueBackground = "#D1DFEE";
        const yellow = "#FFC337";
        const orange = "#ED6742";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D5";

        return (
            <div style={{display: 'grid'}}>
                <img style={{objectFit: "contain", margin: '24px auto', height: '200px'}} src={require(`../assets/images/avatar5.png`)} />
                <h1 className="survey-title" style={{textAlign: 'center', fontSize: '54px', marginTop: '0px', marginBottom: '32px'}}><b><span style={{color: blue}}>y</span><span style={{color: red}}>i</span><span style={{color: yellow}}>k</span><span style={{color: green}}> y</span><span style={{color: orange}}>a</span><span style={{color: blue}}>k</span></b></h1>
                <a className="final-submit-btn green-btn-dash" style={{textAlign: 'center'}} onClick={onClick}>{title == "" ? title : "Upgrade to play yik yak virtually"}</a>
            </div>
        );
    }
  }
  
  export default UpgradRequired;