import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import Lottie from "lottie-react";
import LoadingComponent from "../components/LoadingComponent";

function MyGames() {
  const [user, loading, error] = useAuthState(auth);
  const [accountType, setAccountType] = useState("");
  const [allowAudience, setAllowAudience] = useState(false);
  const [activeGames, setActiveGames] = useState([]);
  const [pastGames, setPastGames] = useState([]);
  const [displayHints, setDisplayHints] = useState(false);
  const navigate = useNavigate();
  const [deckType, setDeckType] = useState("kids");
  const [gamePlayType, setGamePlayType] = useState("pollparty");

  const animationData = require(`../assets/images/host-waiting.json`);

  function generateRandomCode(length) {
    var charset = "ABCDEFGHJKLMNPQRSTUVWXYZ",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    // Make sure this doesn't exist in firebase currently
    const q = query(collection(db, "hosts"), where("code", "==", `${retVal}`));
    const potentialDocs = getDocs(q);
    if ((potentialDocs?.docs?.length ?? 0) > 0) {
      return generateRandomCode(length);
    }
    return retVal;
  }

  const fetchAccountType = async () => {
    try {
      if (user == null) {
        setAccountType("missing");
        console.log("No user data");
        return;
      }
      const querySnapshot = await getDocs(collection(db, "customers", user.uid, "subscriptions"));
      var hasSubscription = false;
      var productId = "";
      querySnapshot.forEach((doc) => {
        const status = doc.data().status;
        if (!hasSubscription) {
          if (status == "trialing" || status == "active") {
            console.log(`STATUS: ${status}`)
            hasSubscription = true;
            productId = doc.data().product.id;
          }
        }
      });
      if (hasSubscription) {
        if (productId == "prod_PbBu30DBX1c1j0") {
          setAccountType("premium");
        } else if (productId == "prod_POZmffO88V5Kwe") {
          setAccountType("plus");
        }
      } else {
        console.log("No subscription");
        setAccountType("basic");
      }
    } catch (err) {
      console.error(err);
      console.log("An error occured while fetching user data");
    }
  };

  const fetchActiveGames = async () => {
    const q = query(collection(db, "hosts"), where("ownerId", "==", user?.uid ?? ""), where("active", "==", true));
    const activeRoomQuery = await getDocs(q);
    setActiveGames(activeRoomQuery.docs);
    
    const pastQuery = query(collection(db, "hosts"), where("ownerId", "==", user?.uid ?? ""), where("active", "==", false));
    const pastRoomResult = await getDocs(pastQuery);
    const sortedGames = pastRoomResult.docs.sort(function(a,b){
      return new Date(b.data().createdAt ?? "2024-01-01T00:47:08.780Z") - new Date(a.data().createdAt ?? "2024-01-01T00:47:08.780Z");
    });
    setPastGames(sortedGames);
  };

  const attemptToStartGame = async () => {
    console.log(user?.uid)
    const q = query(collection(db, "hosts"), where("ownerId", "==", user?.uid));
    const allRoomQuery = await getDocs(q);
    console.log(`ALL ROOMS: ${allRoomQuery.docs}`);
    const activeRooms = allRoomQuery.docs.filter((item => {
      return item.data().active == true
    }))
    console.log(`ACTIVE ROOMS: ${activeRooms}, COUNT ${activeRooms.length}`);
    if (activeRooms.length >= 1) {
      alert("Sorry, your account only supports one game of yik yak at a time, finish your other in progress games before continuing.");
      return;
    } else {
      const collectionRef = collection(db, 'hosts');
      const code = generateRandomCode(4);
      const date = new Date().toJSON();
      const newRoomData = {
        ownerId: user?.uid ?? "",
        code: `${code}`,
        allowHints: displayHints,
        allowAudience: allowAudience,
        currentState: "waitingRoom",
        currentRank: 1,
        currentRound: 1,
        active: true,
        gameType: gamePlayType,
        deckType: deckType,
        createdAt: date
      };
      addDoc(collectionRef, newRoomData).then(() => {
        console.log('Document added successfully!');
        navigate(`/host?code=${code}`)
      }).catch((error) => {
      console.log('Error adding document:', error);
      });
    }
  };

  function endGame(id) {
    console.log(`ID: ${id}`);
    const roomRef = doc(db, "hosts", id);
    updateDoc(roomRef, {
      active: false
    });
    fetchActiveGames();
  }

  function deckTypeSelected(type) {
    if (type == "workplace") {
      alert("playing with yik yak workplace is coming soon!");
    } else {
      setDeckType(type);
    }
  };

  function gamePlayTypeSelected(type) {
    setGamePlayType(type)
  };

  function displayPrettyDate(string) {
    const date = new Date(string)
    return date.toLocaleDateString()
  }

  useEffect(() => {
    if (loading) return;

    fetchAccountType();
    fetchActiveGames();
  }, [user, loading]);
  return (
    <div className="full-page-container">
      <div style={{marginTop: '32px', display: 'flex', alignItems: 'center'}}>
          <div style={{marginRight: '16px'}}>
              <h1 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '48px'}}>virtual game play</h1>
              <p className="dashboard-section-subtitle" style={{textAlign: 'left'}}>playing yik yak is now easier than ever! start a new virtual game by selecting options below</p>
          </div>
          <div style={{display: 'grid'}}>
              <img style={{objectFit: "contain", width: '150px'}} src={require(`../assets/images/avatar5.png`)} />
              {/* <a className="final-submit-btn blue-btn-dash" style={{margin: '48px auto auto auto', width: '60%'}} href="https://protalkgame.com/pages/contact" target="blank">Learn How</a> */}
          </div>
          </div>
      {(loading)? (
        <Lottie style={{height: '80px'}} animationData={animationData} />
      ) : (
        <div>
          {(activeGames.length > 0)? (
            <div>
              <h1 className="survey-title" style={{marginTop: '24px', color: 'white'}}>in progress games</h1>
              <div>
              {activeGames.map(game => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <p className="score-text" style={{color: '#ffffff', fontSize: '24px'}}>room code: <span style={{fontSize: '40px', fontWeight: '600', textTransform: 'lowercase'}}>{game.data().code}</span></p>
                    <a className="final-submit-btn blue-btn-dash" style={{cursor: 'pointer', margin: 'auto 16px auto auto', width: 'auto'}} onClick={() => {
                      navigate(`/host?code=${game.data().code}`);
                    }}>view room</a>
                    <a className="final-submit-btn red-btn-dash" style={{cursor: 'pointer', width: 'auto', margin: 'auto 0'}} onClick={() => {
                      endGame(game.id)
                    }}>end game</a>
              </div>
                ))}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <h1 className="survey-title" style={{marginTop: '48px', color: 'white'}}>host a new game</h1>
          <div>
            <div className="dashboard-score-container" style={{background: '#00000000'}}>
                {(accountType == "basic" || accountType == "plus" || accountType == "premium")?
                    (
                    <div style={{width: '100%'}}>
                      <p className="score-text" style={{marginBottom: '6px', color: '#ffffff'}}>gameplay options</p>
                      <p className="score-text" style={{color: '#ffffff', fontWeight: '600', fontSize: '24px'}}>scenario type</p>
                      <div className="segment-div" style={{marginTop: '4px', marginLeft: '0px', marginRight: '0px'}}>
                      <a className={`segment-item ${deckType === "kids" ? "segment-selected": "segment-unselected"}`} style={{marginTop: '0px'}} onClick={() => {deckTypeSelected("kids")}}>kids</a>
                      <a className={`segment-item ${deckType === "workplace" ? "segment-selected": "segment-unselected"}`} style={{marginTop: '0px', marginLeft: '8px'}} onClick={()=> {deckTypeSelected("workplace")}}>workplace</a>
                      {/* <a className={`segment-item ${deckType === "sales" ? "segment-selected": "segment-unselected"}`} style={{marginTop: '0px', marginLeft: '8px'}} onClick={()=> {deckTypeSelected("sales")}}>PRO TALK - SALES</a> */}
                      </div>
                      <p className="score-text" style={{color: '#ffffff', fontWeight: '600', fontSize: '24px'}}>game play</p>
                      <div className="segment-div" style={{marginTop: '4px', marginLeft: '0px', marginRight: '0px', marginBottom: '0px'}}>
                      <a className={`segment-item ${gamePlayType === "pollparty" ? "segment-selected": "segment-unselected"}`} style={{marginTop: '0px', marginLeft: '8px'}} onClick={()=> {gamePlayTypeSelected("pollparty")}}>polling party 🎉</a>
                      <a className={`segment-item ${gamePlayType === "standard" ? "segment-selected": "segment-unselected"}`} style={{marginTop: '0px'}} onClick={() => {gamePlayTypeSelected("standard")}}>round robin 🔄</a>
                      </div>
                      <p className="score-text" style={{marginTop: '0px', color: '#ffffff', fontSize: '18px', fontWeight: '600'}}>
                        {
                        gamePlayType === "standard" ? 
                        '• 2-6 players': 
                        '• 2-12 players'
                        }</p>
                        <p className="score-text" style={{marginTop: '0px', color: '#ffffff', fontSize: '18px', fontWeight: '600'}}>
                        {
                        gamePlayType === "standard" ? 
                        '• perfect for ages 4-7': 
                        '• perfect for ages 7 and up'
                        }</p>
                      <p className="score-text" style={{marginTop: '0px', color: '#ffffff'}}>
                        {
                        gamePlayType === "standard" ? 
                        '\'round robin\' yik yak is perfect for young players. there are no winners or losers, you all play together to learn new skills and move through the ranks to become a \'master communicator\'.': 
                        '\'polling party\' yik yak! players divide into two teams, secretly answer questions about a presented communication challenge, then try to guess the results. the team that correctly guesses the results with more accuracy becomes the winners!'
                        }</p>
                      {/* <p className="score-text" style={{marginBottom: '6px', marginTop: '48px', color: '#ffffff'}}>premium options</p> */}
                      {/* <div style={{display: 'flex'}}>
                        <p className="score-text" style={{color: '#ffffff', fontWeight: '600', fontSize: '24px'}}>advanced hints</p>
                        <a style={{cursor: 'pointer', margin: 'auto 0 auto auto'}} onClick={() => {
                          if (accountType == 'premium') {
                            setDisplayHints(!displayHints);
                          } else {
                            openOverlay();
                          }
                        }}>
                          <FaCheckSquare style={{height: '35px', width: '35px', color: 'white', display: `${displayHints ? '' : 'none'}`}}/>
                          <FaSquare style={{height: '35px', width: '35px', color: 'gray', display: `${displayHints ? 'none' : ''}`}}/>
                        </a>
                      </div>
                      <p className="score-text" style={{marginTop: '0px', marginBottom: '64px'}}>Advanced hints will help suggest solutions when you are presented with Challenges. This will allow those that aren't familiar with common communication challenges feel more confident in their answers.</p> */}
                      <a className="final-submit-btn green-btn-dash" style={{textAlign: 'center', marginTop: '24px'}} onClick={() => {
                        attemptToStartGame();
                      }}>start game</a>
                    </div>
                    ) : (
                      <div style={{margin: 'auto'}}>
                        {(accountType == "")? (
                          <LoadingComponent style={{height: '100px', width: '100%'}} />
                        ) : (
                          <a className="final-submit-btn green-btn-dash" href={`${user == null ? '/account' : '/pricing'}`} style={{width: '100%', textAlign: 'center', margin: 'auto 0'}}>{`${user == null ? 'log in to play yik yak virtually' : 'upgrade your account to play yik yak virtually'}`}</a>
                        )}
                      </div>
                    )}
            </div>
          </div>
          <div>
              <h1 className="survey-title" style={{marginTop: '48px', color: 'white'}}>past games</h1>
              <div>
              {pastGames.map(game => (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', gap: '18px'}}>
                  <div style={{padding: '16px 0'}}>
                    <p className="score-text" style={{color: '#ffffff', marginBlock: '0px', fontSize: '24px', fontWeight: '600', textTransform: 'lowercase'}}>{`${game.data().gameType === 'standard' ? 'round robin' : 'polling party'}`}</p>
                    <p className="score-text" style={{color: '#ffffff', fontSize: '20px', marginBlock: '0px', textTransform: 'lowercase'}}>room code: <b>{game.data().code}</b></p>
                    <p className="score-text" style={{color: '#ffffff', fontSize: '18px', marginBlock: '0px', textTransform: 'lowercase'}}>played on: {displayPrettyDate(game.data().createdAt)}</p>
                 </div>
                  <a className="final-submit-btn blue-btn-dash" style={{cursor: 'pointer', margin: 'auto 16px auto auto', width: 'auto'}} onClick={() => {
                    navigate(`/results?code=${game.data().code}`);
                  }}>view game results</a>
              </div>
                ))}
              </div>
            </div>
        </div>
      )}
    </div>
  );
}

export default MyGames;