import React from "react";
import "./Resources.css";

class PlayerCard extends React.Component {  
    render() {
        const { card, onClick, style } = this.props;
    
        // Colors
        const red = "#CA392D";
        const green = "#409F91";
    
        function getCardType() {
          if (card?.id < 27) {
              return "challenge";
          } else {
              return "solution";
          }
        };
    
        function getCardColor() {
          if (card.id < 27) {
              return red;
          } else {
              return green;
          }
        }; 

      return (
        <div className="player-card" style={{style, border: "8px", borderColor: `${getCardColor()}`, borderStyle: "solid", maxHeight: 'none', display: 'grid'}} onClick={onClick}>
          <p className="player-card-title" style={{color: `${getCardColor()}`, fontWeight: '500'}}>{getCardType()}</p>
          <p className="player-card-name">{card.name}</p>
          <p className="player-card-description">{card.subtitle}</p>
          <img id="cardimg" style={{margin: 'auto', objectFit: "contain", maxWidth: '90%', height: "80px"}} src={require(`../assets/images/${card.id}.png`)} />
          <p className="player-card-description">{card.kidDescription}</p>
        </div>
      );
    }
  }
  
  export default PlayerCard;