import React, { useEffect, useState } from "react";
import { useAuthState, useSignInWithApple, useSignInWithGoogle } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Account.css";
import "../login/Login.css";
import "../App.css";
import { auth, signInWithGoogle, signInWithApple, db, logout } from "../firebase";
import { query, collection, getDoc, getDocs, where, doc } from "firebase/firestore";
import LoadingComponent from "../components/LoadingComponent";
import ManagePlanCell from "../components/ManagePlanCell";
import { FaApple, FaGoogle } from "react-icons/fa";

function Account() {
  const [user, loading] = useAuthState(auth);
  // const [signInWithApple, user] = useSignInWithApple(auth);
  const [accountType, setAccountType] = useState("");
  const [planRenewDate, setPlanRenewDate] = useState("...");
  const navigate = useNavigate();

  const handleSignInWithApple = () => {
    signInWithApple(auth);
  };

  const fetchAccountType = async () => {
    try {
      if (user == null) {
        setAccountType("basic");
        console.log("No user data");
        return;
      }
      const querySnapshot = await getDocs(collection(db, "customers", user.uid, "subscriptions"));
      var hasSubscription = false;
      var productId = "";
      querySnapshot.forEach((doc) => {
        const status = doc.data().status;
        const renewDate = doc.data()["trial_end"].toDate().toDateString();
        if (!hasSubscription) {
          if (status == "trialing" || status == "active") {
            hasSubscription = true;
            productId = doc.data().product.id;
            setPlanRenewDate(renewDate);
          }
        }
      });
      if (hasSubscription) {
        if (productId == "prod_Pp6vu0QgLQkANr") {
          setAccountType("premium");
        } else if (productId == "prod_Pp6sRoYVIQHfFo") {
          setAccountType("plus");
        }
      } else {
        setAccountType("basic");
      }
    } catch (err) {
      console.error(err);
      console.log("An error occured while fetching user data");
    }
  };

  const red = "#C65447";
  const redBackground = "#F4DDDA";
  const green = "#509E6F";
  const greenBackground = "#DCECE2";
  const blue = "#1961A8";
  const blueBackground = "#D1DFEE";
  const yellow = "#FFC337";
  const orange = "#ED6742";
  const gold = "#F0B52C";
  const goldBackground = "#FCF0D5";

  const loadingAnimationData = require(`../assets/images/host-waiting.json`);

  useEffect(() => {
    if (loading) return;
    fetchAccountType();
  }, [user, loading]);
  return (
    <div className="account">
      {(loading)? (
        <div style={{minHeight: '1200px'}}>
          <LoadingComponent style={{marginTop: '24px', height: '80px'}}/>
        </div>
      ) : (
        <div>
          {(user == null)? (
            <div style={{marginTop: '80px', minHeight: '1200px'}}>
              <h1 className="dashboard-section-title" style={{fontSize: '80px'}}><span style={{color: '#ffffff'}}>yik yak</span></h1>
              <p className="dashboard-section-subtitle">play yik yak virtually and access all digital content</p>
              <img style={{objectFit: "contain", height: '200px', width: '100%', marginBottom: '32px'}} src={require(`../assets/images/avatar5.png`)} />
              <div style={{display: 'grid'}}>
                <a className="login__btn login__google" onClick={signInWithGoogle} style={{margin: '16px auto', width: '190px', paddingLeft: '32px', paddingRight: '32px'}}>
                  <FaGoogle className="button-img" />sign in with google</a>
              </div>
              <div style={{display: 'grid'}}>
                <a className="login__btn login__google" onClick={() => handleSignInWithApple()} style={{margin: '0px auto', width: '190px', paddingLeft: '32px', paddingRight: '32px'}}>
                <FaApple className="button-img"/>sign in with apple</a>
              </div>
            </div>
          ) : (
            <div className="page-container" style={{maxWidth: '1200px'}}>
              <h1 className="survey-title" style={{marginTop: '24px', color: 'white'}}>my account</h1>
              <div className="dashboard-score-container" style={{background: '#00000024'}}>
                <div style={{width: '100%'}}>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h4 style={{fontWeight: '500', color: 'white', fontSize: '20px'}}>name</h4>
                    <h4 className="score-text" style={{textAlign: 'end', color: '#dddddd'}}>{user?.displayName ?? ""}</h4>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h4 style={{fontWeight: '500', color: 'white', fontSize: '20px'}}>email</h4>
                    <h4 className="score-text" style={{textAlign: 'end', color: '#dddddd'}}>{user?.email ?? ""}</h4>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h4 style={{fontWeight: '500', color: 'white', fontSize: '20px'}}>looking for some help?</h4>
                    <a className="final-submit-btn blue-btn-dash" style={{textAlign: 'end', width: 'auto', margin: 'auto 0'}} href="mailto:klay@kedapps.com" target="blank">contact us</a>
                  </div>
                  {(loading)? (
                    <LoadingComponent style={{height: '80px'}}/>
                  ) : (
                    <ManagePlanCell accountType={accountType} planRenewDate={planRenewDate}/>
                  )}
                  <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: '16px'}}>
                    <h4 style={{fontWeight: '500', margin: '24px auto'}} className="final-submit-btn red-btn-dash" onClick={() => { if (window.confirm('Are you sure you would like to log out?')) logout() }}>log out</h4>   
                  </div>
                </div>
              </div>    
            </div>
          )}
         </div>
      )}
    </div>
  );
}
export default Account;