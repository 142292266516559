import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where, onSnapshot, doc, updateDoc, deleteField } from "firebase/firestore";
import { PURPLE, RED, YELLOW, GREEN, ORANGE, BLUE } from "../components/Colors.js";

import UpgradRequired from "../components/UpgradeRequired";
import HostWaitingRoom from "./HostWaitingRoom";
import HostTutorial from "./HostTutorial";
import HostPlaying from "./HostPlaying";
import HostCreateGame from "./HostCreateGame";
import LoadingComponent from "../components/LoadingComponent";
import HostPlayingPollParty from "./pollparty/HostPlayingPollParty.js";

function Host() {
    const [user, loading] = useAuthState(auth);
    const [accountType, setAccountType] = useState("");
    const [roomKey, setRoomKey] = useState(null);
    const [activeHost, setActiveHost] = useState(null);
    const [secondsRemaining, setSecondsRemaining] = useState(75);

    const navigate = useNavigate();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const roomCode = urlSearchParams.get("code") ?? "";

    const [solutionCards, setSolutionCards] = useState([]);
    const [challengeCards, setChallengeCards] = useState([]);

    const [remainingChallengeCards, setRemainingChallengeCards] = useState([]);
    const [remainingSolutionCards, setRemainingSolutionCards] = useState([]);

     // Constants
     const freshChallengeCards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26];
     const freshSolutionCards = [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52];

    const fetchAccountType = async () => {
        try {
            if (user === null) {
            setAccountType("basic");
            console.log("No user data");
            return;
            }
            const querySnapshot = await getDocs(collection(db, "customers", user.uid, "subscriptions"));
            var hasSubscription = false;
            var productId = "";
            querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const status = doc.data().status;
            if (!hasSubscription) {
                if (status === "trialing" || status === "active") {
                hasSubscription = true;
                productId = doc.data().product.id;
                }
            }
            });
            if (hasSubscription) {
            if (productId === "prod_PbBu30DBX1c1j0") {
                setAccountType("premium");
            } else if (productId === "prod_Pp6sRoYVIQHfFo") {
                setAccountType("plus");
            }
            } else {
            setAccountType("basic");
            }
        } catch (err) {
            console.error(err);
            console.log("An error occured while fetching user data");
        }
    };

    const fetchCurrentGame = async () => {
        try {
            console.log(user?.uid)
            if (roomCode != "") {
                const q = query(collection(db, "hosts"), where("ownerId", "==", user?.uid), where("active", "==", true), where("code", "==", roomCode));
                const doc = await getDocs(q);
                console.log(`ROOM KEY: ${doc.docs[0].id}`);
                setRoomKey(doc.docs[0].id);
                const data = doc.docs[0].data();
                console.log(data)
                setActiveHost(data);
            } else {
                const q = query(collection(db, "hosts"), where("ownerId", "==", user?.uid), where("active", "==", true));
                const doc = await getDocs(q);
                console.log(`ROOM KEY: ${doc.docs[0].id}`);
                setRoomKey(doc.docs[0].id);
                const data = doc.docs[0].data();
                console.log(data)
                setActiveHost(data);
            }
        } catch (err) {
            console.log("FAILED TO GET ACTIVE HOST")
            console.error(err);
        }
    };

    function kickPlayer(id) {
        if (roomKey != "") {
            const validPlayers = activeHost?.players.filter((player) => {
                return player.id != id;
            });
            const roomRef = doc(db, "hosts", roomKey);
            updateDoc(roomRef, {
                players: validPlayers
            });
        }
    };

    function getSlotCard(index) {
        const indexes = [activeHost?.slot1, activeHost?.slot2, activeHost?.slot3, activeHost?.slot4, activeHost?.slot5, activeHost?.slot6, activeHost?.slot7, activeHost?.slot8, activeHost?.slot9, activeHost?.slot10]
        if (activeHost != null) {
            if (indexes[index] != null) {
                if (indexes[index] > 26) {
                    return solutionCards.filter((card) => {
                        return indexes[index] == card.data().id;
                    })[0]?.data();
                } else {
                    return challengeCards.filter((card) => {
                        return indexes[index] == card.data().id;
                    })[0]?.data();
                }
            }
        }
        return null;
    }

    const fetchSolutions = async () => {
        try {
            console.log("solutions");
            const q = query(collection(db, "solutions"));
            const doc = await getDocs(q);
            const myCards = doc.docs;
            console.log(myCards);
            if (myCards != null) {
                const sortedCards = myCards.sort((a, b) => a.data().id < b.data().id ? -1 : 1)
                setSolutionCards(sortedCards);
            } else {
                console.log("Missing Card Data");
                return;
            }
        } catch (err) {
            console.log("An error occured while fetching card data");
            console.error(err);
        }
    };
  
    const fetchChallenges = async () => {
        try {
            const q = query(collection(db, "challenges"));
            const doc = await getDocs(q);
            const myCards = doc.docs;
            if (myCards != null) {
                const sortedCards = myCards.sort((a, b) => a.data().id < b.data().id ? -1 : 1)
                setChallengeCards(sortedCards);
            } else {
                console.log("Missing Card Data");
                return;
            }
        } catch (err) {
            console.log("An error occured while fetching card data");
            console.error(err);
        }
    };

    function calculateNewRequirement(rank) {
        if (rank == 2) {
            return 2;
        } else if (rank == 3) {
            return 2;
        } else if (rank == 4) {
            return 3;
        } else if (rank == 5) {
            return 4;
        } else if (rank == 6) {
            return 4;
        } else if (rank == 7) {
            return 5;
        } else if (rank == 8) {
            return 5;
        }
        return 1;
    };

    function displayRankCompleteIfNeeded(roomKey, host) {
        // Reset project if all slots are satisfied
        console.log('displayRankCompleteIfNeeded');
        if (host.currentState === 'nextRank') {
            return;
        }
        const roomRef = doc(db, "hosts", roomKey);
        var shouldNotifyVIP = false;
        if (host.requirementAmount == 1 && host.slot1 != null && host.slot2 != null) {
            console.log("WAIT FOR VIP TO MOVE TO THE NEXT RANK");
            shouldNotifyVIP = true;
        } else if (host.requirementAmount == 2 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null) {
            console.log("WAIT FOR VIP TO MOVE TO THE NEXT RANK");
            shouldNotifyVIP = true;
        } else if (host.requirementAmount == 3 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null) {
            console.log("WAIT FOR VIP TO MOVE TO THE NEXT RANK");
            shouldNotifyVIP = true;
        } else if (host.requirementAmount == 4 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null && host.slot7 != null && host.slot8 != null) {
            console.log("WAIT FOR VIP TO MOVE TO THE NEXT RANK");
            shouldNotifyVIP = true;
        } else if (host.requirementAmount == 5 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null && host.slot7 != null && host.slot8 != null && host.slot9 != null && host.slot10 != null) {
            console.log("YOU WON THE GAME!");
            shouldNotifyVIP = true;
        }
        if (shouldNotifyVIP) {
            updateDoc(roomRef, {
                currentState: "waitingForVIPToProceed",
                vipNextNeeded: true
            });
        }
    }

    function incrementToTheNextRankIfNeeded(roomKey, host) {
        // Reset project if all slots are satisfied
        console.log('incrementToTheNextRankIfNeeded');
        if (host.vipNextNeeded || host.currentState === "waitingForVIPToProceed") {
            console.log('NOT READY YET');
            return;
        }
        try {
            const roomRef = doc(db, "hosts", roomKey);
            if (host.requirementAmount == 1 && host.slot1 != null && host.slot2 != null) {
                console.log("WE COMPLETED A RANK!")
                const newRank = host.currentRank + 1;
                const newRequirement = calculateNewRequirement(newRank);
                updateDoc(roomRef, {
                    currentState: 'playing',
                    currentRank: newRank,
                    slot1: deleteField(),
                    slot2: deleteField(),
                    slot3: deleteField(),
                    slot4: deleteField(),
                    requirementAmount: newRequirement
                });
            } else if (host.requirementAmount == 2 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null) {
                console.log("WE COMPLETED A RANK!")
                const newRank = host.currentRank + 1;
                const newRequirement = calculateNewRequirement(newRank);
                updateDoc(roomRef, {
                    currentState: 'playing',
                    currentRank: newRank,
                    slot1: deleteField(),
                    slot2: deleteField(),
                    slot3: deleteField(),
                    slot4: deleteField(),
                    requirementAmount: newRequirement
                });
            } else if (host.requirementAmount == 3 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null) {
                console.log("WE COMPLETED A RANK!")
                const newRank = host.currentRank + 1;
                const newRequirement = calculateNewRequirement(newRank);
                updateDoc(roomRef, {
                    currentState: 'playing',
                    currentRank: newRank,
                    slot1: deleteField(),
                    slot2: deleteField(),
                    slot3: deleteField(),
                    slot4: deleteField(),
                    slot5: deleteField(),
                    slot6: deleteField(),
                    requirementAmount: newRequirement
                });
            } else if (host.requirementAmount == 4 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null && host.slot7 != null && host.slot8 != null) {
                console.log("WE COMPLETED A RANK!")
                const newRank = host.currentRank + 1;
                const newRequirement = calculateNewRequirement(newRank);
                updateDoc(roomRef, {
                    currentState: 'playing',
                    currentRank: newRank,
                    slot1: deleteField(),
                    slot2: deleteField(),
                    slot3: deleteField(),
                    slot4: deleteField(),
                    slot5: deleteField(),
                    slot6: deleteField(),
                    slot7: deleteField(),
                    slot8: deleteField(),
                    requirementAmount: newRequirement
                });
            } else if (host.requirementAmount == 5 && host.slot1 != null && host.slot2 != null && host.slot3 != null && host.slot4 != null && host.slot5 != null && host.slot6 != null && host.slot7 != null && host.slot8 != null && host.slot9 != null && host.slot10 != null) {
                console.log("YOU WON THE GAME!")
                const newRank = host.currentRank + 1;
                updateDoc(roomRef, {
                    currentState: 'playing',
                    currentRank: newRank,
                    slot1: deleteField(),
                    slot2: deleteField(),
                    slot3: deleteField(),
                    slot4: deleteField(),
                    slot5: deleteField(),
                    slot6: deleteField(),
                    slot7: deleteField(),
                    slot8: deleteField(),
                    slot9: deleteField(),
                    slot10: deleteField()
                });
            } 
        } catch {
            console.log('Increment To Next Failed')
        }
    }

    function currentRoundSelections(host) {
        const currentRound = host?.currentRound ?? 1
        switch (currentRound) {
        case 1:
            return host?.round1Selections ?? []
        case 2:
            return host?.round2Selections ?? []
        case 3:
            return host?.round3Selections ?? []
        default:
            return host?.round1Selections ?? []
        }
    }

    function currentRoundSolutions(host) {
        const currentRound = host?.currentRound ?? 1
        switch (currentRound) {
        case 1:
            return host?.round1Solutions ?? []
        case 2:
            return host?.round2Solutions ?? []
        case 3:
            return host?.round3Solutions ?? []
        default:
            return host?.round1Solutions ?? []
        }
    }

    function checkIfPollingHasCompleted(roomKey, host) {
        console.log(`time remaining: ${host?.timeRemaining ?? 99}`)
        if (host?.currentState === 'polling') {
            // Check Submissions
            const totalSelections = currentRoundSelections(host).length ?? 0
            if (totalSelections !== host?.players.length) {
                return;
            }
            const submittedSelections = currentRoundSelections(host).filter((selection) => { return selection.submitted === true})?.length ?? 0
            const firstPlayer = host?.players.random();
            // Calculate ranks for solutions based on polling
            var solutionsWithPoints = currentRoundSolutions(host).map((solution) => { return { solution: solution, points: 0, revealed: false } });
            console.log(`total selections: ${submittedSelections} & player count ${host?.players.length}`);
            console.log(`solutionsWithPoints: ${solutionsWithPoints}`);
            if (submittedSelections !== host?.players.length) {
                return;
            }
            for (var i = 0; i < currentRoundSelections(host).length; i++) {
                const selections = currentRoundSelections(host)[i] ?? [];
                for(var j = 0; j < selections.ranks.length; j++) {
                    var pointsToAdd = 0;
                    if (j === 0) {
                        pointsToAdd = 6;
                    } else if (j === 1) {
                        pointsToAdd = 5;
                    } else if (j === 2) {
                        pointsToAdd = 4;
                    } else if (j === 3) {
                        pointsToAdd = 3;
                    } else if (j === 4) {
                        pointsToAdd = 2;
                    } else if (j === 5) {
                        pointsToAdd = 1;
                    }
                    var solutionToAddPointsTo = solutionsWithPoints.filter((sP => { return sP.solution === selections.ranks[j]; }))[0];
                    solutionToAddPointsTo.points += pointsToAdd;
                }
            }
            const sorted = solutionsWithPoints.sort((a, b) => a.points < b.points ? 1 : -1);
            console.log(`total: ${totalSelections} submitted: ${submittedSelections}`);
            if (totalSelections === submittedSelections) {
                const roomRef = doc(db, "hosts", roomKey);
                if (host?.currentRound === 1) {
                    updateDoc(roomRef, {
                        currentState: 'party',
                        round1Results: sorted,
                        currentPlayer: firstPlayer
                    });
                } else if (host?.currentRound === 2) {
                    updateDoc(roomRef, {
                        currentState: 'party',
                        round2Results: sorted,
                        currentPlayer: firstPlayer
                    });
                } else if (host?.currentRound === 3) {
                    updateDoc(roomRef, {
                        currentState: 'party',
                        round3Results: sorted,
                        currentPlayer: firstPlayer
                    });
                }
            }
        }
        if ((host?.timeRemaining ?? 75) === 0) {
            const roomRef = doc(db, "hosts", roomKey);
            updateDoc(roomRef, {
                currentState: 'party'
            });
        }
    }

    const attemptToStartGame = async () => {
        // Check for at least 2 players
        if (activeHost?.players.length < 2) {
            alert("Please be sure that there are 2 players in your room before you start your game!");
            return;
        }
        if (activeHost.gameType === 'pollparty') {
            const team1Count = (activeHost?.players.filter((p) => { return p.team === 1 || p.team == null })).length ?? 0
            const team2Count = (activeHost?.players.filter((p) => { return p.team === 2 })).length ?? 0
            if (Math.abs(team1Count - team2Count) > 1) {
                alert("In order to start your game the teams should be more even 😊");
                return;
            };
        }
        if (roomKey != "") {
            console.log(`ROOM KEY: ${roomKey}`);
            const roomRef = doc(db, "hosts", roomKey);
            await updateDoc(roomRef, {
            currentState: "tutorial"
            });
        }
    };

    const skipTutorial = () => {
        if (roomKey != "") {
            if (activeHost?.gameType === "pollparty") {
                startPollParty();
            } else {
                // startRoundRobinGame();
            }
        }
    };

    const startPollParty = () => {
        const roomRef = doc(db, "hosts", roomKey);

        // Shuffle the cards
        const newChallenges = shuffle(freshChallengeCards);
        const newSolutions = shuffle(freshSolutionCards);

        const round1Solutions = newSolutions.slice(0, 8);
        const round2Solutions = newSolutions.slice(8, 16);
        const round3Solutions = newSolutions.slice(16, 24);

        updateDoc(roomRef, {
            currentState: "polling",
            timeRemaining: 75,
            currentRound: 1,
            ranksToFind: [1, 2, 3, 4],
            round1Challenge: newChallenges[0],
            round2Challenge: newChallenges[1],
            round3Challenge: newChallenges[2],
            round1Solutions: round1Solutions,
            round2Solutions: round2Solutions,
            round3Solutions: round3Solutions
        });
    };

    function shuffle(array) {
        let currentIndex = array.length, randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex > 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
        
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]
            ];
        }
        return array;
    };

    function checkIfRoundHasCompleted(roomKey, host) {
        if (host?.currentState === 'party') {
            if (host.ranksToFind.length === 0) {
            }
        }
    }

    function moveToNextRound() {
        var round = activeHost?.currentRound ?? 1;
        if (round === 3) {
            return;
        }
        round++;
        var ranksToFind = [1, 2, 3, 4];
        if (round === 2) {
            ranksToFind = [2, 3, 4, 5];
        } else if (round === 3) {
            ranksToFind = [8]
        }
        const roomRef = doc(db, "hosts", roomKey);
        updateDoc(roomRef, {
            currentRound: round,
            ranksToFind: ranksToFind,
            currentState: 'polling'
        });
    }

    function endGame() {
        const roomRef = doc(db, "hosts", roomKey);
        updateDoc(roomRef, {
            active: false,
            currentState: 'hostEnded'
        });
        navigate('/my-games');
    }

    function startTimer(roomKey, host) {
        // var seconds = secondsRemaining;
        // if (host.currentState !== "polling") {
        //     console.log('timer skipped');
        //     return;
        // }
        // setInterval(function(){
        //     console.log('timer decrement');
        //     if (seconds === 0) {
        //         clearInterval(this);
        //         return;
        //     } else {
        //         seconds--;
        //         setSecondsRemaining(seconds);
        //         console.log(`time remaining ${secondsRemaining}`);
        //     }
        // }, 1000);
    }

    useEffect(() => {
        if (loading) {
            return;
        }
        fetchCurrentGame();
        fetchSolutions();
        fetchChallenges();
        fetchAccountType();
        const q = query(collection(db, "hosts"), where("ownerId", "==", user?.uid), where("active", "==", true));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                setActiveHost(change.doc.data());
                setRoomKey(change.doc.id);
                // ONLY FOR STANDARD GAME PLAY
                if (change.doc.data().gameType == "standard") {
                    displayRankCompleteIfNeeded(change.doc.id, change.doc.data());
                    if (change.doc.data().currentState === "nextRank") {
                        incrementToTheNextRankIfNeeded(change.doc.id, change.doc.data());
                    }
                } else if (change.doc.data().gameType === "pollparty") {
                    // check if polling has completed
                    startTimer(change.doc.id, change.doc.data());
                    checkIfPollingHasCompleted(change.doc.id, change.doc.data());
                    checkIfRoundHasCompleted(change.doc.id, change.doc.data());
                } 
            });
        });
    }, [loading, user, roomKey]);
    return (
        <div style={{background: 'white', minHeight: '900px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <a className="survey-title" href="/" target="blank" style={{textAlign: 'center', fontSize: '3vw', margin: '4px 16px', marginBottom: '0px', textDecoration: 'none'}}><b><span style={{color: PURPLE}}>y</span><span style={{color: RED}}>i</span><span style={{color: YELLOW}}>k</span><span style={{color: GREEN}}> y</span><span style={{color: ORANGE}}>a</span><span style={{color: BLUE}}>k</span></b></a>
                <a className="survey-title" style={{textAlign: 'center', fontSize: '1.6vw', color: RED, margin: '4px 16px', marginBottom: '0px', textDecoration: 'none', cursor: 'pointer'}} onClick={() => {endGame()}}>end game</a>
            </div>
            {(loading)? (
                <LoadingComponent style={{height: '100px'}}/>
            ) : (
                <div className="require-large-space" style={{paddingBottom: '48px'}}>
                    <div className="dashboard-score-container" style={{background: '#00000000', paddingBottom: '0px'}}>
                        {(accountType === "basic" || accountType === "premium")? (
                            <div style={{width: '100%'}}>
                                {(activeHost?.gameType === 'standard')? (
                                    <div>
                                        <HostWaitingRoom 
                                            roomKey={roomKey}
                                            style={{display: `${activeHost?.currentState === 'waitingRoom' ? '' : 'none'}`}} 
                                            activeHost={activeHost} 
                                            onClick={(playerId) => {
                                                attemptToStartGame();
                                                // kickPlayer(playerId);
                                            }}
                                            // onClickStart={attemptToStartGame}
                                        />
                                        <HostTutorial style={{display: `${activeHost?.currentState === 'tutorial' ? '' : 'none'}`}} activeHost={activeHost} />
                                        <HostPlaying style={{display: `${((activeHost?.currentState === 'playing' && activeHost?.gameType === 'standard') || activeHost?.currentState === 'waitingForVIPToProceed' || activeHost?.currentState === 'nextRank') ? '' : 'none'}`}} activeHost={activeHost} slot1={getSlotCard(0)} slot2={getSlotCard(1)} slot3={getSlotCard(2)} slot4={getSlotCard(3)} slot5={getSlotCard(4)} slot6={getSlotCard(5)} slot7={getSlotCard(6)} slot8={getSlotCard(7)} slot9={getSlotCard(8)} slot10={getSlotCard(9)} />
                                        <HostCreateGame style={{display: `${(activeHost?.currentState == null || activeHost?.currentState === 'hostEnded' || activeHost?.currentState === 'ended') ? '' : 'none'}`}} onClick={() => { }}/>
                                    </div>
                                ) : (
                                    <div>
                                        <HostWaitingRoom style={{display: `${activeHost?.currentState === 'waitingRoom' ? '' : 'none'}`}} activeHost={activeHost} onClick={(playerId) => {
                                            attemptToStartGame();
                                            // kickPlayer(playerId);
                                        }} />
                                        <HostTutorial style={{display: `${activeHost?.currentState === 'tutorial' ? '' : 'none'}`}} activeHost={activeHost} onClick={() => {
                                            skipTutorial();
                                        }} />
                                        <HostPlayingPollParty activeHost={activeHost} solutions={solutionCards} challenges={challengeCards} secondsRemaining={secondsRemaining} onClick={() => {
                                            moveToNextRound();
                                        }}/>
                                        <HostCreateGame style={{display: `${(activeHost?.currentState == null || activeHost?.currentState === 'ended') ? '' : 'none'}`}} onClick={() => { }}/>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div style={{margin: 'auto'}}>
                            {(accountType === "")? (
                                <div>
                                    <LoadingComponent style={{width: '100px'}}/>
                                </div>
                            ) : (
                                <UpgradRequired
                                    title="upgrade to host virtual yik yak"
                                    onClick={() => {
                                        if (user != null) {
                                            navigate('/pricing')
                                        } else {
                                            navigate('/account')
                                        }
                                        
                                    }}
                                />
                            )}
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="warning-no-space"><h1 style={{margin: 'auto'}}>please ensure you are playing on a large enough screen so everyone can enjoy playing together</h1></div>
        </div>
    );
}

Array.prototype.random = function () {
    return this[Math.floor((Math.random()*this.length))];
}

export default Host;