import React from "react";
import "../App.css";
import { PURPLE, BLUE, GREEN, ORANGE, RED, RED_BACKGROUND, YELLOW } from "../components/Colors";
import {  db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";

class PlayerPlayingPollParty extends React.Component {  
    render() {
        const { roomKey, activeHost, challenges, solutions, style, currentPlayer, isCurrentPlayer } = this.props;

        var numberSelected = 0;

        function fetchRoundChallenge() {
            if (challenges != null) {
                if (activeHost?.currentRound === 1) {
                    var foundChallenge = challenges?.filter((c => { return Number(c.id) === Number(activeHost?.round1Challenge ?? 1) }))[0];
                    return foundChallenge?.data();
                } else if (activeHost?.currentRound === 2) {
                    var foundChallenge = challenges?.filter((c => { return Number(c.id) === Number(activeHost?.round2Challenge ?? 1) }))[0];
                    return foundChallenge?.data();
                } else if (activeHost?.currentRound === 3) {
                    var foundChallenge = challenges?.filter((c => { return Number(c.id) === Number(activeHost?.round3Challenge ?? 1) }))[0];
                    return foundChallenge?.data();
                } else {
                    return null;
                }
            } else {
                return null;
            }
        };

        function fetchSolution(solution) {
            if (solutions != null) {
                var foundSolution = solutions?.filter((s => { return Number(s.id) === Number(solution) }))[0];
                return foundSolution?.data();
            } else {
                return null;
            }
        };

        function fetchChoiceNumber() {
            const round = activeHost?.currentRound ?? 1
            if (round === 1) {
                return 4;
            } else if (round === 2) {
                return 5;
            } else if (round === 3) {
                return 6;
            }
        };

        function selectedSolution(solution) {
            if (activeHost?.currentRound === 2) {
                selectedSolutionsRound2(solution)
            } else if (activeHost?.currentRound === 3) {
                selectedSolutionsRound3(solution)
            } else {
                selectedSolutionsRound1(solution)
            }
        };

        function selectedSolutionsRound1(solution) {
            if (numberSelected === 4) {
                numberSelected = 0;
                // return;
            }
            console.log(`numberSelected: ${numberSelected}`);
            const roomRef = doc(db, "hosts", roomKey);
            var playerId = currentPlayer?.id;
            if (playerId === null) {
                console.log(`currentPlayerId: ${currentPlayer?.id}`);
                return;
            }
            const slotString = `slot${numberSelected}`;
            console.log(`slotString: ${slotString}`);
            console.log(`round1Selections: ${activeHost?.round1Selections?.length}`);
            var addedItem = false;
            if ((activeHost?.round1Selections?.length ?? 0) === 0) {
                activeHost.round1Selections = [{"playerId": playerId, ranks: [solution]}];
                addedItem = true;
            } else {
                for (var i = 0; i < activeHost?.round1Selections.length; i++) {
                    console.log(`searchingFor playerId: ${playerId}`);
                    if (activeHost.round1Selections[i].playerId === playerId) {
                        console.log(`found playerId: ${playerId}`);
                        if (activeHost.round1Selections[i].ranks.length === 4) {
                            return;
                        }
                        if (activeHost.round1Selections[i].ranks.includes(solution)) {
                            // skip
                            return;
                        }
                        activeHost.round1Selections[i].ranks.push(solution);
                        addedItem = true;
                    }
                }
            }
            if (addedItem === false) {
                activeHost.round1Selections.push({"playerId": playerId, ranks: [solution]});
            }

            updateDoc(roomRef, {
                round1Selections: activeHost?.round1Selections
            });
        };

        function selectedSolutionsRound2(solution) {
            if (numberSelected === 5) {
                numberSelected = 0;
                // return;
            }
            console.log(`numberSelected: ${numberSelected}`);
            const roomRef = doc(db, "hosts", roomKey);
            var playerId = currentPlayer?.id;
            if (playerId === null) {
                console.log(`currentPlayerId: ${currentPlayer?.id}`);
                return;
            }
            const slotString = `slot${numberSelected}`;
            console.log(`slotString: ${slotString}`);
            console.log(`round2Selections: ${activeHost?.round2Selections?.length}`);
            var addedItem = false;
            if ((activeHost?.round2Selections?.length ?? 0) === 0) {
                activeHost.round2Selections = [{"playerId": playerId, ranks: [solution]}];
                addedItem = true;
            } else {
                for (var i = 0; i < activeHost?.round2Selections.length; i++) {
                    console.log(`searchingFor playerId: ${playerId}`);
                    if (activeHost.round2Selections[i].playerId === playerId) {
                        console.log(`found playerId: ${playerId}`);
                        if (activeHost.round2Selections[i].ranks.length === 5) {
                            return;
                        }
                        if (activeHost.round2Selections[i].ranks.includes(solution)) {
                            // skip
                            return;
                        }
                        activeHost.round2Selections[i].ranks.push(solution);
                        addedItem = true;
                    }
                }
            }
            if (addedItem === false) {
                activeHost.round2Selections.push({"playerId": playerId, ranks: [solution]});
            }

            updateDoc(roomRef, {
                round2Selections: activeHost?.round2Selections
            });
        };

        function selectedSolutionsRound3(solution) {
            if (numberSelected === 6) {
                numberSelected = 0;
                // return;
            }
            console.log(`numberSelected: ${numberSelected}`);
            const roomRef = doc(db, "hosts", roomKey);
            var playerId = currentPlayer?.id;
            if (playerId === null) {
                console.log(`currentPlayerId: ${currentPlayer?.id}`);
                return;
            }
            const slotString = `slot${numberSelected}`;
            console.log(`slotString: ${slotString}`);
            console.log(`round3Selections: ${activeHost?.round3Selections?.length}`);
            var addedItem = false;
            if ((activeHost?.round3Selections?.length ?? 0) === 0) {
                activeHost.round3Selections = [{"playerId": playerId, ranks: [solution]}];
                addedItem = true;
            } else {
                for (var i = 0; i < activeHost?.round3Selections.length; i++) {
                    console.log(`searchingFor playerId: ${playerId}`);
                    if (activeHost.round3Selections[i].playerId === playerId) {
                        console.log(`found playerId: ${playerId}`);
                        if (activeHost.round3Selections[i].ranks.length === 6) {
                            return;
                        }
                        if (activeHost.round3Selections[i].ranks.includes(solution)) {
                            // skip
                            return;
                        }
                        activeHost.round3Selections[i].ranks.push(solution);
                        addedItem = true;
                    }
                }
            }
            if (addedItem === false) {
                activeHost.round3Selections.push({"playerId": playerId, ranks: [solution]});
            }

            updateDoc(roomRef, {
                round3Selections: activeHost?.round3Selections
            });
        };

        function scrollToTop() {
            window.scrollTo(0, 0);
        }

        function chooseSolutionForCurrentRank(solution) {
            // Check solution against current ranks currently looking at
            const roomRef = doc(db, "hosts", roomKey);
            const currentPlayerTeam = currentPlayer.team ?? 1;
            var youGotOne = false;

            var host = activeHost;
            if (host == null) {
                console.log('no host');
                return;
            }
            for (var i = 0; i < (activeHost?.ranksToFind?.length ?? 0); i++) {
                const currentRank = activeHost?.ranksToFind[i];
                switch (activeHost?.currentRound ?? 1) {
                case 1:
                    if (host.round1Results[currentRank - 1].solution === solution) {
                        // Congrats you got one!
                        alert("Nice Shot! Your team earned a coin!");
                        youGotOne = true;
                        if (currentPlayerTeam === 1) {
                            var team1Coins = host.team1Coins ?? 0;
                            team1Coins += 1;
                            updateDoc(roomRef, {
                                alreadyPickedItems: [],
                                team1Coins: team1Coins
                            });
                        } else {
                            var team2Coins = host.team2Coins ?? 0;
                            team2Coins += 1;
                            updateDoc(roomRef, {
                                alreadyPickedItems: [],
                                team2Coins: team2Coins
                            });
                        }
                        // remove the rank
                        host.ranksToFind.splice(i, 1);
                        // update the revealed list
                        for (var j = 0; j < activeHost?.round1Results?.length; j++) {
                            if (activeHost?.round1Results[j].solution === solution) {
                                host.round1Results[j].revealed = true;

                                updateDoc(roomRef, {
                                    ranksToFind: host.ranksToFind,
                                    round1Results: host.round1Results
                                });
                                break;
                            }
                        }
                        break;
                    }
                    break;
                case 2:
                    console.log(`round 2 current solution is actually ${host.round2Results[currentRank - 1].solution}`);
                    if (host.round2Results[currentRank - 1].solution === solution) {
                        // Congrats you got one!
                        alert("Nice Shot! Your team earned a coin!");
                        youGotOne = true;
                        if (currentPlayerTeam === 1) {
                            var team1Coins = host.team1Coins ?? 0;
                            team1Coins += 1;
                            updateDoc(roomRef, {
                                alreadyPickedItems: [],
                                team1Coins: team1Coins
                            });
                        } else {
                            var team2Coins = host.team2Coins ?? 0;
                            team2Coins += 1;
                            updateDoc(roomRef, {
                                alreadyPickedItems: [],
                                team2Coins: team2Coins
                            });
                        }
                        // remove the rank
                        host.ranksToFind.splice(i, 1);
                        for (var j = 0; j < activeHost?.round2Results?.length; j++) {
                            if (activeHost?.round2Results[j].solution === solution) {
                                host.round2Results[j].revealed = true;
                                updateDoc(roomRef, {
                                    ranksToFind: host.ranksToFind,
                                    round2Results: host.round2Results
                                });
                                break;
                            }
                        }
                        break;
                    }
                    break;
                case 3:
                    console.log(`round 3 current solution is actually ${host.round3Results[currentRank - 1].solution}`);
                    if (host.round3Results[currentRank - 1].solution === solution) {
                        // Congrats you got one!
                        youGotOne = true;
                        alert("Nice Shot! Your team earned a coin!");
                        if (currentPlayerTeam === 1) {
                            var team1Coins = host.team1Coins ?? 0;
                            team1Coins += 1;
                            updateDoc(roomRef, {
                                alreadyPickedItems: [],
                                team1Coins: team1Coins
                            });
                        } else {
                            var team2Coins = host.team2Coins ?? 0;
                            team2Coins += 1;
                            updateDoc(roomRef, {
                                alreadyPickedItems: [],
                                team2Coins: team2Coins
                            });
                        }
                        // remove the rank
                        if (currentRank <= 8) {
                            const newRankToMoveTo = currentRank - 1;
                            if (newRankToMoveTo === 1) {
                                host.ranksToFind = [];
                            } else {
                                host.ranksToFind = [newRankToMoveTo]
                            }
                        }
                        for (var j = 0; j < activeHost?.round3Results?.length; j++) {
                            if (activeHost?.round3Results[j].solution === solution) {
                                host.round3Results[j].revealed = true;
                                updateDoc(roomRef, {
                                    ranksToFind: host.ranksToFind,
                                    round3Results: host.round3Results
                                });
                                break;
                            }
                        }
                        break;
                    }
                    break;
                default:
                    break
                }
            }
            if (!youGotOne) {
                console.log(`you missed!`);
                var alreadyPickedItems = activeHost.alreadyPickedItems ?? [];
                alreadyPickedItems.push(solution);
                updateDoc(roomRef, {
                    alreadyPickedItems: alreadyPickedItems,
                });
                alert("Nope, that one wasn't correct. Better luck next time.");
            }
            scrollToTop();
            // If we have made it this far we didn't find anything show, a message to the user saying they got it wrong :/ 
            // Move to the next player in the list still
            var lastTeam1Index = host.lastTeam1Index ?? 0;
            var lastTeam2Index = host.lastTeam2Index ?? 0;
            if (currentPlayerTeam === 1) {
                // Look for team 2 player
                const team2Players = host.players.filter((player) => { return player.team === 2; })
                var indexOfCurrentPlayer = host.lastTeam2Index ?? 0;
                indexOfCurrentPlayer += 1;
                if (indexOfCurrentPlayer === (team2Players.length)) {
                    indexOfCurrentPlayer = 0;
                }
                lastTeam2Index = indexOfCurrentPlayer;
                host.currentPlayer = team2Players[indexOfCurrentPlayer];
            } else {
                // Look for team 1 player
                const team1Players = host.players.filter((player) => { return (player.team ?? 1) === 1; });
                var indexOfCurrentPlayer = host.lastTeam1Index ?? 0;
                indexOfCurrentPlayer += 1;
                if (indexOfCurrentPlayer === (team1Players.length)) {
                    indexOfCurrentPlayer = 0;
                }
                lastTeam1Index = indexOfCurrentPlayer;
                host.currentPlayer = team1Players[indexOfCurrentPlayer];
            }
            // Update current player
            updateDoc(roomRef, {
                lastTeam1Index: lastTeam1Index,
                lastTeam2Index: lastTeam2Index,
                currentPlayer: host.currentPlayer
            });
            // Update historical record
            var history = host.history ?? [];
            const lastUpdatedAt = new Date().toJSON();
            history.push({player: currentPlayer, guessedSolution: solution, currentRound: host.currentRound, wasCorrect: youGotOne, createdAt: lastUpdatedAt})
            updateDoc(roomRef, {
                history: history,
                lastUpdatedAt: lastUpdatedAt
            });
        };

        function currentRoundSelections() {
            switch (activeHost?.currentRound ?? 1) {
            case 1:
                return activeHost.round1Selections ?? [];
            case 2:
                return activeHost.round2Selections ?? [];
            case 3:
                return activeHost?.round3Selections ?? [];
            default:
                return [];
            }
        }

        function submitSolutions() {
            const roomRef = doc(db, "hosts", roomKey);
            var playerId = currentPlayer?.id;
            const lastUpdatedAt = new Date().toJSON();
            for (var i = 0; i < roundSelections().length; i++) {
                console.log(`searchingFor playerId: ${playerId}`);
                if (currentRoundSelections()[i].playerId === playerId) {
                    console.log(`found playerId: ${playerId}`);
                    switch (activeHost.currentRound) {
                    case 1:
                        if (activeHost.round1Selections[i].ranks.length === 4) {
                            activeHost.round1Selections[i].submitted = true;
                            updateDoc(roomRef, {
                                round1Selections: activeHost?.round1Selections
                            });
                        } else { alert("Please make sure you have selected 4 ranks"); return; }
                        break;
                    case 2:
                        if (activeHost.round2Selections[i].ranks.length === 5) {
                            activeHost.round2Selections[i].submitted = true;
                            updateDoc(roomRef, {
                                round2Selections: activeHost?.round2Selections
                            });
                        } else { alert("Please make sure you have selected 5 ranks"); return; }
                        break;
                    case 3:
                        if (activeHost.round3Selections[i].ranks.length === 6) {
                            activeHost.round3Selections[i].submitted = true;
                            updateDoc(roomRef, {
                                round3Selections: activeHost?.round3Selections
                            });
                        } else { alert("Please make sure you have selected 6 ranks"); return; }
                        break;
                    default:
                        break
                    }
                }
            }
            updateDoc(roomRef, {
                lastUpdatedAt: lastUpdatedAt
            });
        }

        function roundSolutions() {
            if (activeHost.currentRound === 2) {
                return activeHost.round2Solutions ?? []
            } else if (activeHost.currentRound === 3) {
                return activeHost.round3Solutions ?? []
            } else {
                return activeHost.round1Solutions ?? []
            }
        }

        function roundSelections() {
            if (activeHost.currentRound === 2) {
                return activeHost.round2Selections ?? []
            } else if (activeHost.currentRound === 3) {
                return activeHost.round3Selections ?? []
            } else {
                return activeHost.round1Selections ?? []
            }
        }

        function displaySelectedRank(solution) {
            if (roundSelections().filter((item) => { return item.playerId === currentPlayer?.id; }) === null) {
                console.log('missing ranks');
                return '';
            }
            const ranks = roundSelections().filter((item) => { return item.playerId === currentPlayer?.id; })[0]?.ranks ?? [];
            if (ranks.length === 0) {
                return '';
            }
            if (ranks[0] === solution) {
                return '1st';
            } else if (ranks[1] === solution) {
                return '2nd';
            } else if (ranks[2] === solution) {
                return '3rd';
            } else if (ranks[3] === solution) {
                return '4th';
            } else if (ranks[4] === solution) {
                return '5th';
            } else if (ranks[5] === solution) {
                return '6th';
            } else {
                return '';
            }
        };

        function currentRoundResults() {
            const round = activeHost?.currentRound ?? 1;
            if (round === 1) {
                return activeHost?.round1Results ?? [];
            } else if (round === 2) {
                return activeHost?.round2Results ?? [];
            } else if (round === 3) {
                return activeHost?.round3Results ?? [];
            }
        }

        function hasBeenRevealed(solution) {
            const results = currentRoundResults();
            if (results.length === 0) {
                return false;
            }
            const foundSolution = results.filter((r => { return r.revealed === true && r.solution === solution }))[0]?.solution ?? 0;
            if (foundSolution === solution) {
                return true;
            } else {
                return false;
            }
        };

        function hasBeenMissed(solution) {
            const alreadyPickedItems = activeHost.alreadyPickedItems ?? []
            const foundSolution = alreadyPickedItems.filter((item => { return item === solution }))[0] ?? 0;
            console.log(`already picked: ${alreadyPickedItems}`);
            console.log(`foundSolution: ${foundSolution}`);
            return foundSolution == solution;
        }

        function colorForSelectedRank(solution) {
            if (roundSelections().filter((item) => { return item.playerId === currentPlayer?.id; }) === null) {
                console.log(`missing ranks: ${roundSelections().filter((item) => { return item.playerId === currentPlayer?.id; }).ranks}`);
                return '#FFFFFF';
            }
            const ranks = roundSelections().filter((item) => { return item.playerId === currentPlayer?.id; })[0]?.ranks ?? [];
            if (ranks.length === 0) {
                return '#FFFFFF';
            }
            if (activeHost?.currentRound === 1) {
                if (ranks[0] === solution) {
                    return GREEN;
                } else if (ranks[1] === solution) {
                    return YELLOW;
                } else if (ranks[2] === solution) {
                    return ORANGE;
                } else if (ranks[3] === solution) {
                    return RED;
                } else {
                    return '#FFFFFF';
                }
            } else if (activeHost?.currentRound === 2) {
                if (ranks[0] === solution) {
                    return BLUE;
                } else if (ranks[1] === solution) {
                    return GREEN;
                } else if (ranks[2] === solution) {
                    return YELLOW;
                } else if (ranks[3] === solution) {
                    return ORANGE;
                } else if (ranks[4] === solution) {
                    return RED;
                } else {
                    return '#FFFFFF';
                }
            } else if (activeHost?.currentRound === 3) {
                if (ranks[0] === solution) {
                    return BLUE;
                } else if (ranks[1] === solution) {
                    return BLUE;
                } else if (ranks[2] === solution) {
                    return GREEN;
                } else if (ranks[3] === solution) {
                    return GREEN;
                } else if (ranks[4] === solution) {
                    return ORANGE;
                } else if (ranks[5] === solution) {
                    return ORANGE;
                } else {
                    return '#FFFFFF';
                }
            } else {
                return '#FFFFFF';
            }
        };

        function clearSelections() {
            const roomRef = doc(db, "hosts", roomKey);
            var playerId = currentPlayer?.id;
            if (playerId === null) {
                console.log(`currentPlayerId: ${currentPlayer?.id}`);
                return;
            }
            if (activeHost?.currentRound === 1) {
                if ((activeHost?.round1Selections?.length ?? 0) === 0) {
                    return;
                } else {
                    for (var i = 0; i < activeHost?.round1Selections.length; i++) {
                        console.log(`searchingFor playerId: ${playerId}`);
                        if (activeHost.round1Selections[i].playerId === playerId) {
                            console.log(`found playerId: ${playerId}`);
                            activeHost.round1Selections[i].ranks = [];
                        }
                    }
                }
                updateDoc(roomRef, {
                    round1Selections: activeHost?.round1Selections
                });
            } else if (activeHost?.currentRound === 2) {
                if ((activeHost?.round2Selections?.length ?? 0) === 0) {
                    return;
                } else {
                    for (var i = 0; i < activeHost?.round2Selections.length; i++) {
                        console.log(`searchingFor playerId: ${playerId}`);
                        if (activeHost.round2Selections[i].playerId === playerId) {
                            console.log(`found playerId: ${playerId}`);
                            activeHost.round2Selections[i].ranks = [];
                        }
                    }
                }
                updateDoc(roomRef, {
                    round2Selections: activeHost?.round2Selections
                });
            } else if (activeHost?.currentRound === 3) {
                if ((activeHost?.round3Selections?.length ?? 0) === 0) {
                    return;
                } else {
                    for (var i = 0; i < activeHost?.round3Selections.length; i++) {
                        console.log(`searchingFor playerId: ${playerId}`);
                        if (activeHost.round3Selections[i].playerId === playerId) {
                            console.log(`found playerId: ${playerId}`);
                            activeHost.round3Selections[i].ranks = [];
                        }
                    }
                }
                updateDoc(roomRef, {
                    round3Selections: activeHost?.round3Selections
                });
            }
        };

        function isSubmitted() {
            const check = ((roundSelections().filter((item) => { return item.playerId === currentPlayer?.id; })[0]?.submitted ?? false) === true);
            console.log(`has submitted? ${check}`);
            return check;
        }

        function moveToNextRound() {
            var round = activeHost?.currentRound ?? 1;
            if (round === 3) {
                return;
            }
            round++;
            var ranksToFind = [1, 2, 3, 4];
            if (round === 2) {
                ranksToFind = [2, 3, 4, 5];
            } else if (round === 3) {
                ranksToFind = [8]
            }
            const roomRef = doc(db, "hosts", roomKey);
            updateDoc(roomRef, {
                currentRound: round,
                ranksToFind: ranksToFind,
                currentState: 'polling'
            });
        }


        return (
            <div>
                <div style={{display: `${(activeHost?.currentState === 'polling') ? '' : 'none'}`}}>
                    <div style={{background: '#33333310', width: '100%'}}>
                        <p style={{textAlign: 'center', color: 'white', fontSize: '20px', fontWeight: '600'}}>round {activeHost?.currentRound ?? 1}</p>
                        <div style={{margin: '0 auto', width: 'fit-content', background: RED, borderRadius: '24px'}}>
                            <p style={{color: 'white', textAlign: 'center', padding: '12px 24px 0px 24px', marginBlock: '0px'}}>current challenge</p>
                            <p style={{color: 'white', textAlign: 'center', padding: '0px 24px 0px 24px', fontSize: '24px', fontWeight: '600', marginBlock: '0px'}}>{fetchRoundChallenge()?.name}</p>
                            <p style={{color: 'white', textAlign: 'center', padding: '0px 24px 12px 24px', fontSize: '18px', fontWeight: '400', fontStyle: 'italic', marginBlock: '0px'}}>{fetchRoundChallenge()?.subtitle}</p>
                        </div>
                        {(isSubmitted())? (
                            <div><p style={{textAlign: 'center', fontSize: '18px', color: 'white', padding: '0px 24px', marginTop: '24px'}}><span style={{fontWeight: '600', fontSize: '20px'}}>Submitted!</span><br></br><br></br>Remember your selections below! They will be important to help your team win!</p></div>
                        ) : (
                            <div>
                                <p style={{textAlign: 'center', fontSize: '18px', color: 'white', padding: '0px 24px'}}>use your device to rank the {fetchChoiceNumber()} solutions you see that resolve this challenge from <span style={{fontWeight: '600', color: GREEN}}>best</span> to <span style={{fontStyle: 'italic', fontWeight: '600', color: RED}}>worst</span></p>
                                <div style={{display: 'flex', justifyContent: 'space-around', margin: '0px 16px', gap: '16px'}}>
                                    <p className="final-submit-btn blue-btn-dash" style={{padding: '12px', marginBlock: '0px'}} onClick={() => {clearSelections()}}>Clear Selection</p>
                                    <p className="final-submit-btn green-btn-dash" style={{padding: '12px', marginBlock: '0px'}} onClick={() => {submitSolutions()}}>Submit</p>
                                </div>
                            </div>
                        )}
                        
                        {roundSolutions().map(solution => (
                            <div style={{display: 'flex', margin: '8px 16px', border: `2px ${colorForSelectedRank(solution)}50 solid`, background: `${colorForSelectedRank(solution)}20`, borderRadius: '2px', cursor: 'pointer'}} onClick={() => {selectedSolution(solution)}}>
                                <img style={{marginLeft: '16px', objectFit: "contain", height: '80px', width: '80px', padding: '10px 0px', display: 'block'}} src={require(`../assets/images/${(fetchSolution(solution)?.id ?? 0)}.png`)} />
                                <div style={{display: 'flex', padding: '16px', marginBlock: '0px', justifyContent: 'center', flexDirection: 'column'}}>
                                    <p style={{textAlign: 'textStart', marginBlock: '0px', color: 'white', fontWeight: '600', fontSize: '24px'}}>{fetchSolution(solution)?.name}</p>
                                    <p style={{textAlign: 'textStart', marginBlock: '0px', color: 'white', fontWeight: '400', fontSize: '14px', fontStyle: 'italic'}}>{fetchSolution(solution)?.subtitle}</p>
                                </div>
                                <h1 style={{display: 'flex', alignItems: 'center', textAlign: 'textEnd', color: `${colorForSelectedRank(solution)}`, marginBlock: '0px', margin: '0 12px 0 auto'}}>{displaySelectedRank(solution)}</h1>
                            </div>
                        ))}
                        <h1 style={{marginBlock: '0px'}}>.</h1>
                    </div>
                </div>
                <div style={{display: `${(activeHost?.currentState === 'party') ? '' : 'none'}`}}>
                    <div style={{width: '100%'}}>
                        {((activeHost?.ranksToFind?.length ?? 0) === 0)? (
                            <div>
                                <p style={{textAlign: 'center', fontSize: '20px', fontWeight: '600', marginBlock: '0px', margin: '24px 0', color: 'white'}}>round {activeHost?.currentRound} complete! 🎉</p>
                                {/* <a className="final-submit-btn blue-btn-dash" style={{maxWidth: '200px', margin: '24px auto', display: `${currentPlayer.isVIP ? '': 'none'}`}} onClick={() => {
                                    moveToNextRound();
                                }}>continue</a> */}
                            </div>
                        ) : (
                            <div>
                                <p style={{textAlign: 'center', color: 'white', fontSize: '20px', fontWeight: '600'}}>round {activeHost?.currentRound ?? 1}</p>
                                <div style={{margin: '0 auto', width: 'fit-content', background: RED, borderRadius: '24px'}}>
                                    <p style={{color: 'white', textAlign: 'center', padding: '12px 24px 0px 24px', marginBlock: '0px'}}>current challenge</p>
                                    <p style={{color: 'white', textAlign: 'center', padding: '0px 24px 0px 24px', fontSize: '24px', fontWeight: '600', marginBlock: '0px'}}>{fetchRoundChallenge()?.name}</p>
                                    <p style={{color: 'white', textAlign: 'center', padding: '0px 24px 12px 24px', fontSize: '18px', fontWeight: '400', fontStyle: 'italic', marginBlock: '0px'}}>{fetchRoundChallenge()?.subtitle}</p>
                                </div>
                                {(isCurrentPlayer)? (
                                    <div>
                                        <p style={{textAlign: 'center', fontSize: '32px', color: 'white', padding: '0px 24px'}}>it's your turn!</p>
                                        <p style={{textAlign: 'center', fontSize: '18px', color: 'white', padding: '0px 24px'}}>tap on one of the solutions below you feel are in the highlighted spots shown on the screen</p>
                                        {roundSolutions().filter((sol) => !hasBeenRevealed(sol))?.map(solution => (
                                            <div style={{display: 'flex', margin: '8px 16px', opacity: `${hasBeenMissed(solution) ? 0.5 : 1.0}`, border: `2px #FFFFFF50 solid`, background: `#FFFFFF20`, borderRadius: '2px', cursor: 'pointer'}} onClick={() => {
                                                if (!hasBeenMissed(solution)) {
                                                    chooseSolutionForCurrentRank(solution)
                                                }
                                                }}>
                                                <img style={{marginLeft: '16px', objectFit: "contain", height: '80px', width: '80px', padding: '10px 0px', display: 'block'}} src={require(`../assets/images/${(fetchSolution(solution)?.id ?? 0)}.png`)} />
                                                <div style={{display: 'flex', padding: '16px', marginBlock: '0px', justifyContent: 'center', flexDirection: 'column'}}>
                                                    <p style={{textAlign: 'textStart', marginBlock: '0px', color: 'white', fontWeight: '600', fontSize: '24px'}}>{fetchSolution(solution)?.name}</p>
                                                    <p style={{textAlign: 'textStart', marginBlock: '0px', color: 'white', fontWeight: '400', fontSize: '14px', fontStyle: 'italic'}}>{fetchSolution(solution)?.subtitle}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ):(
                                    <div>
                                        <p style={{textAlign: 'center', fontSize: '18px', color: 'white', padding: '0px 24px'}}><span style={{textAlign: 'center', fontSize: '32px', color: 'white', padding: '0px 24px'}}>it's {activeHost?.currentPlayer?.name ?? "someone"}'s turn</span><br></br>watch closely, your turn may be coming up soon</p>
                                        {roundSolutions().filter((sol) => !hasBeenRevealed(sol))?.map(solution => (
                                            <div style={{display: 'flex', margin: '8px 16px', opacity: `${hasBeenMissed(solution) ? 0.5 : 1.0}`, border: `2px #FFFFFF50 solid`, background: `#FFFFFF20`, borderRadius: '2px', cursor: 'pointer'}}>
                                                <img style={{marginLeft: '16px', objectFit: "contain", height: '80px', width: '80px', padding: '10px 0px', display: 'block'}} src={require(`../assets/images/${(fetchSolution(solution)?.id ?? 0)}.png`)} />
                                                <div style={{display: 'flex', padding: '16px', marginBlock: '0px', justifyContent: 'center', flexDirection: 'column'}}>
                                                    <p style={{textAlign: 'textStart', marginBlock: '0px', color: 'white', fontWeight: '600', fontSize: '24px'}}>{fetchSolution(solution)?.name}</p>
                                                    <p style={{textAlign: 'textStart', marginBlock: '0px', color: 'white', fontWeight: '400', fontSize: '14px', fontStyle: 'italic'}}>{fetchSolution(solution)?.subtitle}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        <h1 style={{marginBlock: '0px'}}>.</h1>
                    </div>
                </div>
            </div>
        );
    }
}

Array.prototype.random = function () {
    return this[Math.floor((Math.random()*this.length))];
}
  
export default PlayerPlayingPollParty;