import React, { useRef, useEffect, useState } from "react";
import "./Resources.css";

class RankCard extends React.Component {  
    render() {
      const { rank, numberOfCardsRequired, rotation, left, right } = this.props;

      const red = "#C65447";
      const green = "#4E9961";
      const purple = "#977AE8";

      function rankName(rank) {
        if (rank == 1) {
          return "mis-communicator"
        } else if (rank == 2) {
          return "poor communicator"
        } else if (rank == 3) {
          return "okay communicator"
        } else if (rank == 4) {
          return "good communicator"
        } else if (rank == 5) {
          return "excellent communicator"
        } else if (rank == 6) {
          return "amazing communicator"
        } else if (rank == 7) {
          return "ultimate communicator"
        } else if (rank == 8) {
          return "master communicator"
        }
        return "unknown"
      }

      function rankImage(rank) {
        if (rank == 1) {
          return 6
        } else if (rank == 2) {
          return 5
        } else if (rank == 3) {
          return 4
        } else if (rank == 4) {
          return 3
        } else if (rank == 5) {
          return 42
        } else if (rank == 6) {
          return 34
        } else if (rank == 7) {
          return 49
        } else if (rank == 8) {
          return 35
        }
        return "unknown"
      }

      return (
        <div className="project-card" style={{border: "10px", borderColor: purple, borderStyle: "solid", margin: '0px auto', left: `${left}px`, right: `${right}px`, height: '300px', display: 'grid', rotate: `${rotation}deg`}}>
          <p className="card-title" style={{color: purple, fontWeight: '600'}}>{`rank ${rank}`}</p>
          <p className="card-name" style={{fontSize: '32px', fontWeight: '600', margin: 'auto 0px'}}>{`${rankName(rank)}`}</p>
          <img id="cardimg" style={{paddingLeft: "20px", paddingRight: "20px", objectFit: "contain", width: "80%", height: "120px"}} src={require(`../assets/images/${rankImage(rank)}.png`)} />
          <p style={{fontSize: "14px", textAlign: "center", height: "auto", marginTop: "auto", paddingLeft: "4px", paddingRight: "4px", color: "gray", fontWeight: '500'}}><span style={{color: red}}>{`${numberOfCardsRequired} Challenge${numberOfCardsRequired == 1 ? '' : 's'}`}</span> + <span style={{color: green}}>{`${numberOfCardsRequired} Solution${numberOfCardsRequired == 1 ? '' : 's'}`}</span> <span style={{fontWeight: '500'}}>to move to the next rank</span></p>
        </div>
      );
    }
  }
  
  export default RankCard;